/**
 * ! Change CustomModal by your component name
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { Dialog } from "primereact/dialog";
import CustomButton from "./custom-button.js";

const CustomModal = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "custom-modal-" + ticks;

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [visible, setVisible] = useState(false);
	const [position, setPosition] = useState("right");

	function show(position) {
		setPosition(position);
		setVisible(true);
		// add a backdrop behind the modal
	}

	var buttons = [];
	var showButton = props.showButton;
	componentDidMount();

	function componentDidMount() {
		try {
			var increment = 0;
			props.showButton.actionFunction = [() => show("right")];
			showButton = setButton(props.showButton, increment);
			//Loaded
			{
				props.buttons.forEach((element) => {
					buttons.push(setButton(element, increment++));
				});
			}
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(e);}
		}
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: props.trNamespace }), `Custom Modal ${error}`);
	}

	const headerContent = (
		<div>
			<h1>
				{props.useTranslation
					? t(props.componentName, { ns: props.trNamespace })
					: props.componentName}
			</h1>
		</div>
	);
	// const footerContent = buttons.length > 0 ? <div>{buttons}</div> : null;

	function setFooter(buttons) {
		// for each button in the array add it in a <div className="m-0">
		// return the div
		if (buttons.length === 0) return null;
		var footer = [];
		buttons.forEach((element, id) => {
			footer.push(
				<div className="m-0" key={componentId + "-" + id++}>
					{element}
				</div>
			);
		});

		return (
			<div className="card-footer mt-4 w-100 container-inline-spaced">
				{footer}
			</div>
		);
	}

	function setButton(button, increment) {
		if (button.isCloseModal) {
			if (button.actionFunction == null) button.actionFunction = [];
			button.actionFunction.push(() => setVisible(false));
			button.dataId = `close-modal-${props.componentId}`;
		}
		return (
			<CustomButton key={`${componentId}-${increment}`} properties={button} />
		);
	}

	return (
		<div key={componentId}>
			<div className="m-2">{showButton}</div>
			<Dialog
				className={props.className}
				data-id={props.componentId}
				visible={visible}
				position={position}
				modal={true}
				style={{ width: "50vw" }}
				breakpoints={{ "960px": "75vw", "641px": "100vw" }}
				onHide={() => setVisible(false)}
				draggable={false}
				resizable={false}
				footer={setFooter(buttons)}
			>
				<div className="card h-100">
					<div className="card-header bg-primary-gradient">
						<div className="card-title m-4">{headerContent}</div>
					</div>
					{props.content}
				</div>
			</Dialog>
		</div>
	);
};

CustomModal.propTypes = {
	componentId: PropTypes.string,
	trNamespace: PropTypes.string,
	componentName: PropTypes.string,
	subTitle: PropTypes.string,
	content: PropTypes.string,
	showButton: PropTypes.object,
	buttons: PropTypes.array,
	useTranslation: PropTypes.bool,
	className: PropTypes.string
};

export default CustomModal;
