/**
 * ! Change CompanyImages by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { LoadCompanyImages } from "../../../data/services/load-data-service.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



const CompanyImages = (_props) => {
	var props = _props.properties;
	const componentId =
		"CompanyImages-" + Math.random().toString(36).substring(7);
	const _companyModel = require("../../models/company.js");

	const [loaded, setLoadedState] = useState(false);
	const [images, setImages] = useState([]);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	var _cpLocal = _companyModel.getCompanyDatas();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			var images = await LoadCompanyImages(_cpLocal.companyId);
			setImages(images);
			setLoadFunction(images);
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	// Execute after load until all is ok.
	function setLoadFunction(data) {
		try {
			if (data != null) {
				// something with data
				setLoadedState(true);
			}
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 250);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}

	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}


	function setCarouselImages() {
		var img = images.map((image, index) => {
			index++;
			return (
				<SwiperSlide key={`comp-img-${image.Id}-${index}`}

				>
					<img
						className="img-full-width  border-rounded"

						src={`data:image/png;base64,${image.Image}`}
					/>
					<div className="carousel-caption card carousel-content-sm"

					>
						<h5 className="carousel-content-inner trn">{image.Description}</h5>
					</div>
				</SwiperSlide>
			);
		});
		return img;
	}


	function setSwiper() {
		return (
			<Swiper
				key={componentId}
				spaceBetween={10}
				slidesPerView={1}
				effect={'cards'}
				loop={images.length > 2}
				grabCursor={true}
				centeredSlides={true}
				style={{ maxWidth: "900px" }}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true
				}}
				mousewheel={true}
				pagination={{
					clickable: true,
				}}
				modules={[EffectCards, Autoplay, Pagination, Mousewheel]}
			>
				{setCarouselImages()}{" "}
			</Swiper>)

	}

	return loaded ? setSwiper() : null;
};

CompanyImages.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string
};

export default CompanyImages;
