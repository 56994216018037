import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/application/order";

function GetAll() {
	const route = `${baseUrl}/`;
	return route;
}
function GetFilter() {
	const route = `${baseUrl}/filter`;
	return route;
}
function GetOne() {
	const route = `${baseUrl}/one`;
	return route;
}
function PostCreate() {
	const route = `${baseUrl}/create`;
	return route;
}
function PutUpdate() {
	const route = `${baseUrl}/update`;
	return route;
}
function PutDelete() {
	const route = `${baseUrl}/delete`;
	return route;
}
function GetClientSecret() {
	const route = `${baseUrl}/client-secret`;
	return route;
}
function GetPaymentForm() {
	const route = `${baseUrl}/payment-form`;
	return route;
}

function GetPaymentConfirm() {
	const route = `${baseUrl}/check-payment`;
	return route;
}

function GetTimeSlots() {
	const route = `${baseUrl}/time-slots`;
	return route;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	PostCreate,
	PutUpdate,
	PutDelete,
	GetClientSecret,
	GetPaymentForm,
	GetPaymentConfirm,
	GetTimeSlots
};
