/**
 * ! Change CompaniesSelection by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import CustomSpinner from "../shared/custom-spinner-small.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { LoadAllCompanyDatas } from "../../../data/services/load-data-service.js";
const CompaniesSelection = (_props) => {
    var props = _props.properties;
    const componentId = "CompaniesSelection-" + Math.random().toString(36).substring(7);
    const [loaded, setLoadedState] = useState(false);
    const [companies, setCompanies] = useState(null);

    const { t } = initTranslations();
    const { handleShowToast } = useToastContext();

    useEffect(() => {
        componentDidMount();
    }, [loaded]);

    function componentDidMount() {
        if (companies != null) return;
        try {
            setLoadFunction();
        } catch (e) {
            if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(e);}
        }
    }
    // Execute after load until all is ok.
    async function setLoadFunction() {
        try {
            var data = await LoadAllCompanyDatas();
            setCompanies(data);
            if (data != null) {

                setLoadedState(true);
            }
        } catch {
            setTimeout(() => {
                setLoadFunction(data);
            }, 50);
        }
    }
    /**
     * Handles errors and shows a toast message.
     * @param {Error} error - The error object.
     */
    function onError(error) {
        sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
    }

    function sendToast(message, severity, title) {
        handleShowToast(severity, title, `${message}`);
    }

    function setCarouselImages() {
        if (companies == null) return;
        var ignore = [];
        try {
            ignore = JSON.parse(process.env.REACT_APP_IGNORE_CPY_ID);
        }
        catch {
            ignore = [];
        }

        var img = companies.map((cpy, index) => {
            if (cpy.companyDetails == null || cpy.companyDetails.length == 0 || ignore.find(x => x == cpy.CompanyId)) return;

            index++;
            return (
                <SwiperSlide key={`comp-img-${cpy.CompanyId}-${index}`} >
                    <div className="card" data-id={cpy.CompanyId} onClick={() => {
                        window.location.href = `/${cpy.CompanyId}`;
                    }}>
                        <div className="card-header bg-info-gradient">
                            <h5 className="carousel-content-inner trn">{cpy.companyDetails[0].CompanyName}</h5>
                        </div>
                        <div className="card-body bg-info-gradient">
                            <center>
                                <img
                                    className="img-xl  border-rounded"
                                    src={`data:image/png;base64,${cpy.companyDetails[0].CompanyLogo}`}
                                />
                            </center>
                        </div>
                    </div >
                </SwiperSlide >
            );
        });
        return img;
    }

    return loaded ? (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header bg-primary-gradient border-rounded">
                            <h3>{props.pageName}</h3>
                        </div>
                    </div>

                </div>
            </div>
            <Swiper
                key={componentId}
                spaceBetween={10}
                slidesPerView={1}
                effect={'cards'}
                grabCursor={true}
                centeredSlides={true}
                loop={companies.length > 2}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                mousewheel={true}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCards, Autoplay, Pagination, Mousewheel]}
        >
                {setCarouselImages()}{" "}
            </Swiper>
        </>
    ) : (<div
        className={`col-xs-12 col-md-6 col-lg-4 col-xxl-3 p-2 animate spin delay-1`}
        style={{ height: "300px" }}
        key={componentId}
        id="CompaniesSelection"
    >
        <div className=" card h-100 app-lighter-bg-color">
            <CustomSpinner
                properties={{
                    addClass: "spin-border-l"
                }}
            ></CustomSpinner>
        </div>
    </div>);
};

CompaniesSelection.propTypes = {
    pageName: PropTypes.string,
    trNamespace: PropTypes.string
};

export default CompaniesSelection;
