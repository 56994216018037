import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
const _companyModel = require("../../models/company.js");
/**
 * Checkout form component
 * @param {*} _props
 * @returns
 */
const CheckoutForm = (_props) => {
	//! Properties
	var props = _props.properties;
	const stripe = useStripe();
	const elements = useElements();
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	var _cpLocal = _companyModel.getCompanyDatas();
	var url = `${window.location.origin}/${_cpLocal.companyId}`;
	//! Init
	useEffect(() => {
		if (!stripe) {
			return;
		}
		// Give our token to our form

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					setMessage(t("orders:payment-success"));
					break;
				case "processing":
					setMessage(t("orders:payment-processing"));
					break;
				case "requires_payment_method":
					setMessage(t("orders:payment-failed"));
					break;
				default:
					setMessage(t("common:error-occured"));
					break;
			}
		});
	}, [stripe]);
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`checkout ${error}`, "error", t("common:error"));
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	/**
	 * Handles the form submit event.
	 * @param {*} e
	 * @returns
	 */
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}		
		// get root url
		setIsLoading(true);

		var result = await stripe.confirmPayment({
			elements,
			redirect: "if_required",
			confirmParams: {
				return_url: url
			}
		});
		try {
			if (result.type === "card_error" || result.type === "validation_error") {
				setMessage(result.error?.message);
				onError(result.error?.message);
				setIsLoading(false);
			} else if (result.paymentIntent.status == "succeeded") {
				setMessage("");
				await props.submitCallback(result.paymentIntent);
				setIsLoading(false);
			} else {
				setMessage(result.error?.message);
				onError(result.error?.message);
				setIsLoading(false);
			}
		} catch {
			onError(result.error?.message);
		}

		setIsLoading(false);
	};
	/**
	 * Payment element options
	 */
	const paymentElementOptions = {
		layout: "tabs"
	};
	/**
	 * Checkout form
	 */
	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" options={paymentElementOptions}>
				<span
					className="spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true"
				></span>
			</PaymentElement>
			<button
				disabled={isLoading || !stripe || !elements}
				id="submit"
				className="btn btn-info mt-2"
			>
				<span id="button-text">
					{isLoading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : (
						t("orders:pay-now")
					)}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
};

CheckoutForm.propTypes = {
	submitCallback: PropTypes.func
};
export default CheckoutForm;
