const lsName = "deactivated";

function addDeactivated(cpid, data){
    var item = getDeactivated(cpid);
    if(item!=null && item.deactivated!=null){
        // check if deactivated already exists
        var deactivatedItem = item.deactivated.find((x) => x.id == data.id && x.variant==data.variant);
        if(deactivatedItem!=null){
            // remove deactivated
            item.deactivated.splice(item.deactivated.indexOf(deactivatedItem), 1);
        }
        // push deactivated to deactivatedItem
        item.deactivated.push(data);    
        // save deactivated to localStorage
        setDeactivated(item);    
    }else{
        // create new item
        var newDeactivated = {cpid: cpid,context: process.env.REACT_APP_NAME, deactivated: [data]};
        // save deactivated to localStorage
        setDeactivated(newDeactivated);
    }    
}

function removeDeactivated(cpid, data){
    var item = getDeactivated(cpid);
    if(item!=null && item.deactivated!=null){
        // check if deactivated already exists
        var deactivatedItem = item.deactivated.find((x) =>  x.id == data.id && x.variant==data.variant);
        if(deactivatedItem!=null){
            // remove deactivated
            item.deactivated.splice(item.deactivated.indexOf(deactivatedItem), 1);
            // save deactivated to localStorage
            setDeactivated(item);    
        }
    }
}

function setDeactivated(deactivated){
    var items = __getDeactivated();
    // Check if items contains cpid
    var item = findItem(items, deactivated.cpid);
    if(item!=null){
        // remove item
        items.splice(items.indexOf(item), 1);
    }
    // push deactivated to items
    items.push(deactivated);
    // save deactivated to localStorage
    localStorage.setItem(lsName, JSON.stringify(items));
}

function getDeactivated(cpid){
    var item = null;
	// if localStorage deactivated exists
	var deactivated = __getDeactivated();
	// check if deactivated has item with cpid and context
	if (deactivated != null) {
		try {
			item = findItem(deactivated, cpid)
			if (!item) {
                item = {cpid: cpid,context: process.env.REACT_APP_NAME, deactivated: []};
            }
		} catch(e) {
            console.error(e);
			// remove deactivated
			localStorage.removeItem(lsName);
			__getDeactivated();
		}
	}
	return item;
}

function __getDeactivated() {
	// get deactivated from localStorage
	var items = localStorage.getItem(lsName);
	// if deactivated is null create new []
	if (items == null) {
		items = [];
	} else {
		items = JSON.parse(items);
	}
	return items;
}

function findItem(list, cpid) {
	var item = null;
	try {
		item = list.find(
			(x) => x.cpid === cpid && x.context === process.env.REACT_APP_NAME
		);
	} catch {
		// remove guests
		localStorage.removeItem(lsName);
		__getDeactivated();
	}
	return item;
}


module.exports = {
	getDeactivated,
    addDeactivated,
    removeDeactivated
};
