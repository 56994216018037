const lsName = "company";
/**
 * Set company datas in local storage
 * @param {*} param0
 */
function setCompanyDatas({
	companyId,
	companyName,
	companyLogo,
	currency,
	companyParams
}) {
	var company = {
		companyId: companyId,
		companyName: companyName,
		companyLogo: companyLogo,
		currency: currency,
		ShowProductImage: companyParams.ShowProductImage
	};
	localStorage.setItem(lsName, JSON.stringify(company));
}
/**
 * Get company datas from local storage
 * @returns
 */
function getCompanyDatas() {
	var company = null; // JSON.parse(localStorage.getItem(lsName));
	var url = window.location.pathname;
	var cpid = 0;
	if (url !== "") {
		cpid = url.split("/")[1];
		// check if cpid is a number
		if (!isNaN(cpid)) {
			cpid = parseInt(cpid);
		}
	}
	if (process.env.REACT_APP_COMPANY_ID != null) {
		cpid = process.env.REACT_APP_COMPANY_ID;
	}
	var stored = JSON.parse(localStorage.getItem(lsName));
	if (stored == null || stored.companyId != cpid) {
		stored = {
			companyId: cpid,
			companyName: "",
			companyLogo: "",
			currency: "€",
			companyParams: null
		};
	}
	company = stored;
	company.companyId = parseInt(company.companyId);
	return company;
}

module.exports = { setCompanyDatas, getCompanyDatas };
