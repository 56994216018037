// ! Dashboard datas
/**
 *  Prepare the list of allergens for a product
 * @param {*} list
 * @param {*} itemName
 * @returns
 */
function setLists(list, itemName) {
	var items = null;
	if (list != null && list.length > 0) {
		// add list complement.LinkedIngredients to ingredients
		if (items == null) {
			items = [];
		}
		for (var j = 0; j < list.length; j++) {
			items.push(list[j][itemName]);
		}
	}
	return items;
}
/**
 * Prepare the list of allergens for a product
 * @param {*} product
 * @returns
 */
function prepareAllergenList(product) {
	var allergens = [];
	if (product.ProductComplements == null) return allergens;
	for (var i = 0; i < product.ProductComplements.length; i++) {
		var complement = product.ProductComplements[i];
		if (complement.LinkedIngredients != null) {
			for (var j = 0; j < complement.LinkedIngredients.length; j++) {
				var selectedIng = complement.LinkedIngredients[j];
				var selectedAll = selectedIng.Allergen;
				for (var k = 0; k < selectedAll.length; k++) {
					var name = selectedAll[k].AllergenName;
					if (!allergens.includes(name)) {
						allergens.push(name);
					}
				}
			}
		}
	}
	return allergens;
}
/**
 *  Prepare the list of ingredients for a product
 * @param {*} product
 * @returns
 */
function prepareIngredientList(product) {
	var ingredients = null;
	if (product.ProductComplements == null) return ingredients;
	for (var i = 0; i < product.ProductComplements.length; i++) {
		var complement = product.ProductComplements[i];
		if (complement.LinkedIngredients == null) continue;
		// sort complement.LinkedIngredients by PositionIngredient then by IngredientName if positionIngredient is null then PositionIngredient = 9999
		for (var m = 0; m < complement.LinkedIngredients.length; m++) {
			if (complement.LinkedIngredients[m].PositionIngredient == null) {
				complement.LinkedIngredients[m].PositionIngredient = 9999;
			}
		}
		var lstTemp = complement.LinkedIngredients.sort((a, b) => {
			if (a.PositionIngredient === b.PositionIngredient) {
				// Sort by name
				if (a.IngredientName < b.IngredientName) {
					return -1;
				}
			}
			return a.PositionIngredient - b.PositionIngredient;
		});

		var ingredientLst = setLists(lstTemp, "IngredientName");
		if (ingredientLst != null) {
			for (var j = 0; j < ingredientLst.length; j++) {
				// Check if ingredient is not already in the list
				if (ingredients == null) {
					ingredients = [];
				}
				if (!ingredients.includes(ingredientLst[j])) {
					ingredients.push(ingredientLst[j]);
				}
			}
		}
	}

	return ingredients;
}
/**
 *  Order the category items by position and name
 * @param {*} categoryItems
 * @returns
 */
function orderCategoryItems(categoryItems) {
	// for products in categoryItems if PositionProduct is null then PositionProduct = 9999
	for (var i = 0; i < categoryItems.length; i++) {
		if (categoryItems[i].PositionProduct == null) {
			categoryItems[i].PositionProduct = 9999;
		}
	}
	// sort categoryItems by position then by Name
	var _categoryItems = categoryItems.sort((a, b) => {
		if (a.PositionProduct === b.PositionProduct) {
			// Sort by name
			if (a.ProductName < b.ProductName) {
				return -1;
			}
		}
		return a.PositionProduct - b.PositionProduct;
	});
	return _categoryItems;
}
/**
 *      Show the product image
 * @param {*} item
 * @param {*} showProductImage
 * @returns
 */
function showProductImageFunct(item, showProductImage) {
	var show =
		showProductImage &&
		item.ProductImage != null &&
		item.ProductImage != "" &&
		item.ProductImage.toLowerCase() != "null" &&
		!item.ProductImage.toLowerCase().startsWith("http");

	return show;
}
/**
 *  Show the item
 * @param {*} item
 * @param {*} companyShow
 * @param {*} propName
 * @returns
 */
function show(item, companyShow, propName) {
	var showOption = false;
	if (item.ProductOptions.length > 0) {
		showOption = item.ProductOptions[0][propName];
	}
	var show = companyShow && showOption;
	return show;
}
/**
 *  Show the option
 * @param {*} item
 * @param {*} propName
 * @returns
 */
function showOption(item, propName) {
	var show = false;
	if (item.ProductOptions.length > 0) {
		show = item.ProductOptions[0][propName] ?? false;
	}
	return show;
}
/**
 * Draw the text on the canvas for images not defined
 * @param {*} txt
 * @returns
 */
function draw(txt) {
	var b = document.createElement("canvas");
	b.width = 512;
	b.height = 512;
	var c = b.getContext("2d");
	c.font = "50px Arial";
	c.fillStyle = "rgba(0,0,0,0.15)";
	c.fillRect(0, 0, b.width, b.height);
	c.fillStyle = "white";
	c.rotate(-0.78);
	c.translate(-b.width, b.height / 4 - 55);
	c.scale(1, 1.4);
	var txtHeight = 65;
	var offset = 10;
	var w = Math.ceil(c.measureText(txt).width);
	txt = new Array(w * 2).join(txt + " "); //change the multipler for more lines
	for (var i = 0; i < Math.ceil(b.height / txtHeight); i++) {
		c.fillText(txt, -(i * offset), i * txtHeight);
	}
	return b;
}

function waitForElm(selector) {
	return new Promise((resolve) => {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector));
		}

		const observer = new MutationObserver(() => {
			if (document.querySelector(selector)) {
				observer.disconnect();
				resolve(document.querySelector(selector));
			}
		});

		// If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	});
}

// ! ORDERS

function addToCart(item) {
	if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.warn("Added item " + JSON.stringify(item)); }
}

function hasLinkedIngredients(item) {
	var has = false;
	var count = 0;
	try {
		has = item.ProductComplements != null && item.ProductComplements.length > 0;
		if (has) {
			for (var i = 0; i < item.ProductComplements.length; i++) {
				if (
					item.ProductComplements[i] != null &&
					item.ProductComplements[i].LinkedIngredients != null
				) {
					count += item.ProductComplements[i].LinkedIngredients.length;
				}
			}
		}
		has = count > 0;
	} catch (err) {
		if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.error("ERROR: " + item, err); }
	}

	return { has: has, count: count };
}

function hasCanAddIngredients(item) {
	var has = false;
	var count = 0;
	try {
		has = item.ProductComplements != null && item.ProductComplements.length > 0;
		if (has) {
			for (var i = 0; i < item.ProductComplements.length; i++) {
				if (
					item.ProductComplements[i].ProductDataOptions != null &&
					item.ProductComplements[i].ProductDataOptions.CanAdd != null &&
					item.ProductComplements[i].ProductDataOptions.CanAdd.Ingredients !=
					null
				) {
					count +=
						item.ProductComplements[i].ProductDataOptions.CanAdd.Ingredients
							.length;
				}
			}
		}
		has = count > 0;
	} catch (err) {
		if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.error("ERROR: " + item, err); }
	}

	return { has: has, count: count };
}

function hasLinkedProducts(item) {
	var has = false;
	var count = 0;
	try {
		has = item.ProductComplements != null && item.ProductComplements.length > 0;
		if (has) {
			for (var i = 0; i < item.ProductComplements.length; i++) {
				if (
					item.ProductComplements[i].ProductDataOptions != null &&
					item.ProductComplements[i].ProductDataOptions.LinkedProducts != null
				) {
					count +=
						item.ProductComplements[i].ProductDataOptions.LinkedProducts.length;
				}
			}
		}
		has = count > 0;
	} catch (err) {
		if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.error("ERROR: " + item, err); }
	}
	return { has: has, count: count };
}

function hasDescription(item) {
	var has = item.ProductDescription != null && item.ProductDescription != "";
	return { has: has };
}

function hasCanRemoveIngredients(item) {
	var has = false;
	var count = 0;
	try {
		has = item.ProductComplements != null && item.ProductComplements.length > 0;
		if (has) {
			for (var i = 0; i < item.ProductComplements.length; i++) {
				if (
					item.ProductComplements[i].ProductDataOptions != null &&
					item.ProductComplements[i].ProductDataOptions.CanRemove != null &&
					item.ProductComplements[i].ProductDataOptions.CanRemove.Ingredients !=
					null
				) {
					count +=
						item.ProductComplements[i].ProductDataOptions.CanRemove.Ingredients
							.length;
				}
			}
		}
		has = count > 0;
	} catch (err) {
		if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.error("ERROR: " + item, err); }
	}
	return { has: has, count: count };
}

function hasServedWithProducts(item) {
	var has = false;
	var count = 0;
	try {
		has = item.ProductComplements != null && item.ProductComplements.length > 0;
		if (has) {
			for (var i = 0; i < item.ProductComplements.length; i++) {
				if (
					item.ProductComplements[i].ProductDataOptions != null &&
					item.ProductComplements[i].ProductDataOptions.ServedWith != null &&
					item.ProductComplements[i].ProductDataOptions.ServedWith.length > 0
				) {
					count +=
						item.ProductComplements[i].ProductDataOptions.ServedWith.length;
				}
			}
		}
		has = count > 0;
	} catch (err) {
		if (process.env.REACT_APP_APIS_URL.includes("localhost")) { console.error("ERROR: " + item, err); }
	}
	return { has: has, count: count };
}

function showContent(
	item,
	showProductImage,
	showProductDescription,
	showIngredients,
	canOrder
) {
	if (canOrder) {
		return true;
	}
	var ing = hasLinkedIngredients(item).has && showIngredients;
	var desc = hasDescription(item).has && showProductDescription;
	var img = showProductImageFunct(item, showProductImage);
	var showModal = ing || desc || img;
	return showModal;
}

function orderPromotedProducts(list) {
	// order list by: item.Category.PositionCategory then by item.PositionProduct then by item.Name
	var orderedList = list.sort((a, b) => {
		if (
			a.Category[0].PositionCategory === b.Category[0].PositionCategory ??
			9999
		) {
			if (a.PositionProduct === b.PositionProduct ?? 9999) {
				if (a.ProductName < b.ProductName) {
					return -1;
				}
			}
			return (a.PositionProduct ?? 9999) - (b.PositionProduct ?? 9999);
		}
		return (
			(a.Category[0].PositionCategory ?? 9999) -
			(b.Category[0].PositionCategory ?? 9999)
		);
	});
	return orderedList;
}

module.exports = {
	prepareAllergenList,
	prepareIngredientList,
	orderCategoryItems,
	showProductImageFunct,
	show,
	showOption,
	draw,
	addToCart,
	hasCanAddIngredients,
	hasCanRemoveIngredients,
	hasServedWithProducts,
	waitForElm,
	hasLinkedProducts,
	showContent,
	orderPromotedProducts
};
