import routes from "../../routes/datas/user.js";
import commons from "../common-services-funct.js";
import axios from "axios";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);

async function CheckUserLogin(data) {
	var res = true;
	try {
		res = await post(routes.ValidateLogin(), data);
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}
	return res;
}

async function CreateUserLogin(data) {
	var res = true;
	try {
		var created = await post(routes.CreateOne(), data);
		res = created;
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}
	return res;
}

async function post(url, body) {
	var res = null;
	try {
		var header = commons.SetClassicHeadersFormControl(false);
		var response = await axios.post(url, body, header);
		var data = response.data;
		res = data;
	} catch (err) {
		_logger.ERROR("postClaimAccess", [err]);
	}

	return res;
}

export default {
	CheckUserLogin,
	CreateUserLogin
};
