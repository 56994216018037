import { RootSSO } from "../main-route";

/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
export function PostClaimAccess() {
	const route = RootSSO() + "/user-auth/claim-user-access/";
	return route;
}



