const lsName = "deliveries";
function setDelivery(delivery, cpid) {
	addDelivery(delivery, cpid);
}

function getDelivery(cpid) {
	var item = null;
	// if localStorage delivery exists
	var items = getDeliveries();
	// check if deliverys has item with cpid and context
	if (items != null) {
		var deliv = findItem(items, cpid);
		item = deliv;
		if (item) {
			item = item.delivery;
		}
	}
	return item;
}

function getDeliveries() {
	// get deliveryItem from localStorage
	var items = localStorage.getItem(lsName);
	// if deliveryItem is null create new []
	if (items == null) {
		items = [];
	} else {
		items = JSON.parse(items);
	}
	return items;
}

function addDelivery(delivery, cpid) {
	// get deliveryItem from localStorage
	var items = getDeliveries();
	var newItem = {
		cpid: cpid,
		context: process.env.REACT_APP_NAME,
		delivery: delivery
	};
	if (items.length > 0) {
		try {
			var item = findItem(items, cpid);
			if (item != null) {
				items.splice(items.indexOf(item), 1);
			}
		} catch {
			// remove guests
			localStorage.removeItem(lsName);
			getDeliveries();
		}
	}
	// push delivery to deliveryItem
	items.push(newItem);
	// save paymentState to localStorage
	localStorage.setItem(lsName, JSON.stringify(items));
}

function deleteDelivery(cpid) {
	var item = null;
	// if localStorage delivery exists
	var items = getDeliveries();
	// check if deliverys has item with cpid and context
	if (items != null) {
		var deliv = findItem(items, cpid);
		if (deliv != null) {
			items.splice(items.indexOf(deliv), 1);
		}
		// save delivery
		localStorage.setItem(lsName, JSON.stringify(items));
	}
	return item;
}

function findItem(list, cpid) {
	var item = null;
	try {
		item = list.find(
			(x) => x.cpid === cpid && x.context === process.env.REACT_APP_NAME
		);
	} catch {
		// remove guests
		localStorage.removeItem(lsName);
		getDeliveries();
	}
	return item;
}
module.exports = {
	getDelivery,
	setDelivery,
	deleteDelivery
};
