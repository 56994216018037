/**
 * ! Change SuggestedProducts by your component name
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
//import { LoadData } from "../../../data/services/load-data-service.js";
//import { LogoBo2_80Animation } from "../../../properties/animations/logos-animations.js";
import { setTheme, getTheme } from "../../models/themes.js";
import PromotedProductCard from "../../common/specific/promoted-product-card.js";
import CategoryCard from "../../common/specific/category-card.js";
import CustomSpinner from "../shared/custom-spinner-small.js";

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {
	LoadData,
} from "../../../data/services/load-data-service.js";

const _companyModel = require("../../models/company.js");
const _basketModel = require("../../models/basket.js");
const _deactivatedModel = require("../../models/deactivated.js");


const SuggestedProducts = (_props) => {

	var props = null;
	const ticks = new Date().getTime();
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [loaded, setLoadedState] = useState(false);
	const [companyDatas, setCompanyDatas] = useState(null);
	const [promotedProducts, setPromotedProducts] = useState(null);
	const [unavailableProducts, setUnvailableProducts] = useState(null);
	const _promotedColor = "info";
	const [showAllergens, setShowAllergens] = useState(false);
	const [showIngredients, setShowIngredients] = useState(false);
	const [showNutriscore, setShowNutriscore] = useState(false);
	const [showProductDescription, setShowProductDescription] = useState(false);
	const [showProductImage, setShowProductImage] = useState(false);
	const [canOrder, setCanOrder] = useState(false);

	var _cpLocal = _companyModel.getCompanyDatas();

	setTheme(getTheme());

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			props = _props.properties;
			await loadAll();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.contains("localhost")) { onError(e); }
		}
	}
	async function loadAll() {
		await loadProducts();

	}

	async function loadProducts() {
		if (promotedProducts != null) return;
		var data = await LoadData();
		if (data != null && data.companyName != null) {
			setCompanyDatas(data);
			setShowAllergens(data.company.CompanyParams.ShowAllergens);
			setShowIngredients(data.company.CompanyParams.ShowIngredients);
			setShowNutriscore(data.company.CompanyParams.ShowNutriscore);
			setShowProductDescription(
				data.company.CompanyParams.ShowProductDescription
			);
			setShowProductImage(data.company.CompanyParams.ShowProductImage);
			setCanOrder(data.company.CompanyParams.CanOrder);
			var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
			var productsBasket = basket.products;
			var suggested = [];
			var unavailable = [];
			for (var i = 0; i < productsBasket.length; i++) {
				var element = productsBasket[i];
				var product = data.products.find(
					(x) => x.Id == element.ProductDataId
				);

				if (product != null && product.ProductComplements != null && product.ProductComplements.length > 0) {
					var complement = product.ProductComplements[0];
					if (complement.LinkedProducts != null && complement.LinkedProducts.length > 0) {
						// add linked product to suggested
						complement.LinkedProducts.forEach((linked) => {
							if (!suggested.find((x) => x.Id == linked.Id))
								suggested.push(linked);
						});
					}
					if (element.Variant.variantName != t("menu:price") || element.Variant.variantName != t("menu:classic")) {

						if (complement.ProductVariant != null && complement.ProductVariant.length > 0) {

							for (var num = 0; num < complement.ProductVariant.length; num++) {
								var variant = complement.ProductVariant[num];
								if (variant.VariantName == element.Variant.variantName && variant.deactivated == true) {
									if (!unavailable.find((x) => x.Id == variant.Id))
										unavailable.push(product);
									var deactivatedItem = { id: product.Id, variant: element.Variant.variantName };
									_deactivatedModel.addDeactivated(product.CompanyId, deactivatedItem);
								}
							}
						}
					}
				}

				if (product != null && product.ProductOptions != null && product.ProductOptions.length > 0) {
					var option = product.ProductOptions[0];
					if (option.Deactivated == true) {
						if (!unavailable.find((x) => x.Id == product.Id))
							unavailable.push(product);

						deactivatedItem = { id: product.Id, variant: "" };
						_deactivatedModel.addDeactivated(product.CompanyId, deactivatedItem);
					}

				}
			}
			setPromotedProducts(suggested);
			setUnvailableProducts(unavailable);
			setLoadedState(true);

		}
	}

	function onError(error) {
		sendToast(
			`From Head Menu ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}

	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}



	// ! Suggested
	function setSuggested() {
		if (unavailableProducts != null && unavailableProducts.length > 0) {
			setTitle(t("common:out-of-stock"), "danger");
			return hasUnavailable();
		}
		else {
			if (promotedProducts == null || promotedProducts.length == 0) {
				window.location.href = `/${_cpLocal.companyId}/cart`;
			}
			else {
				setTitle(t("orders:thought-about"), "info");
				return hasSuggestions();
			}
		}

	}

	function setTitle(title, color) {
		var modalTitle = document.getElementById("modal-suggestion-title");
		var modalHeader = document.getElementById("modal-suggestion-header");
		var modalFooter = document.getElementById("modal-suggestion-footer");
		var modalButton = document.getElementById("modal-suggestion-footer-button");
		var modalButtonText = document.getElementById("modal-suggestion-footer-button-txt");
		modalTitle.innerHTML = title;
		modalHeader.className = `modal-header bg-${color}`;
		modalFooter.className = `modal-footer bg-${color}`;
		modalButton.className = `btn btn-${color}-light`;
		modalButtonText.className = `text-black`;
		modalButtonText.innerHTML = t("orders:go-to-cart");
	}

	function hasUnavailable() {
		setTimeout(async () => {
			var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
			var p = await _basketModel.checkProductsDeactivated(basket);
			if (p != null && p.length > 0) {
				var toastContent = "<ul>";
				for (var i = 0; i < p.length; i++) {
					var product = p[i].Product;
					var variant = "";
					var qty = p[i].TotalQty;
					if (p[i].Variant.variantName != t("menu:price") || p[i].Variant.variantName != t("menu:classic")) {
						variant = p[i].Variant.variantName;
					}
					toastContent += `<li>${qty}x ${product} ${variant} ${t("orders:removed-products")}</li>`;
				}
				toastContent += "</ul>";
				sendToast(toastContent, "warning", t("common:out-of-stock"));
			}
		}, 500);

		var swiper = (<div className="row">
			<div className="col-12 p-4">
				<h4 className="text-danger">{t("orders:removed-products")}</h4>
			</div>
			<Swiper
				spaceBetween={10}
				slidesPerView={1}
				effect={'cards'}
				grabCursor={true}
				centeredSlides={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true
				}}
				mousewheel={true}
				pagination={{
					clickable: true,
				}}
				modules={[EffectCards, Autoplay, Pagination, Mousewheel]}
			>
				{setPromotedSwiperComponents(unavailableProducts)}
			</Swiper>

		</div >)
		return swiper;
	}

	function hasSuggestions() {

		// get all categories in promotedProducts
		var categories = [];
		promotedProducts.forEach((item) => {
			var prod = companyDatas.products.find((x) => x.Id == item.Id);
			if (prod != null) {
				var cat = prod.Category;
				if (cat != null && cat.length > 0 && !categories.find((x) => x.Id == cat[0].Id)) {
					categories.push(cat[0]);
				}
			}

		});
		// sort categories by position
		categories = categories.sort((a, b) => {
			return a.PositionCategory ?? 9999 - b.PositionCategory ?? 9999;
		});
		var id = "c_" + ticks + "_" + "suggested";
		var swiper = (<div
			key={id}
			className={`row m-0 p-0 ${`border-rounded`} w-100 shadow`}
		>

			{categories.map((category) => {
				var products = promotedProducts.filter((x) => x.CategoryId == category.Id);
				return (
					<div className="col-12 p-4" key={`sugg-cat-${category.Id}`}>
						<div className="row mb-4">
							<CategoryCard
								properties={{
									pageName: "s-c-" + category.CategoryName,
									trNamespace: "dashboard",
									category: category,
									count: category.id,
									additionalCardClassName: null
								}}
							></CategoryCard>
						</div><div className="row">
							<Swiper
								spaceBetween={10}
								slidesPerView={1}
								effect={'cards'}
								grabCursor={true}
								centeredSlides={true}
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
									pauseOnMouseEnter: true
								}}
								mousewheel={true}
								pagination={{
									clickable: true,
								}}
								modules={[EffectCards, Autoplay, Pagination, Mousewheel]}

							>
								{setPromotedSwiperComponents(products)}{" "}
							</Swiper>
						</div>
					</div>
				);
			})}

		</div>)

		return swiper;
	}

	function setPromotedSwiperComponents(products) {
		return products.map((item, count) => {
			var propsItem = {
				pageName: item.Product,
				trNamespace: "dashboard",
				item: item,
				count: count,
				showAllergens: showAllergens,
				showIngredients: showIngredients,
				showNutriscore: showNutriscore,
				showProductDescription: showProductDescription,
				showProductImage: showProductImage,
				canOrder: canOrder,
				companyDatas: companyDatas,
				additionalModalButtonClassName: `btn-${_promotedColor}`,
				isPromoted: true
			}
			count++;
			return (
				<SwiperSlide key={`suggested-item-${item.Id}-${count}`} className="card-promoted-swiper" style={{ minWidth: "95%" }}>
					<PromotedProductCard
						properties={propsItem}
					></PromotedProductCard>
				</SwiperSlide>
			);
		});
	}


	return loaded ? setSuggested()
		: (<div
			className={`col-xs-12 col-md-6 col-lg-4 w-100 col-xxl-3 p-2 animate spin `}
			style={{ height: "300px" }}
			key={"sp-" + ticks}
			id={"sp-" + ticks}
		>
			<div className=" card h-100 w-100 app-lighter-bg-color">
				<CustomSpinner
					properties={{
						addClass: "spin-border-l"
					}}
				></CustomSpinner>
				<h4 className="text-primary">{t("orders:check-cart")}</h4>
			</div>
		</div>);
};

SuggestedProducts.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	addedClass: PropTypes.string,
	textClass: PropTypes.string,
	menuButtonClass: PropTypes.string,
	showOrders: PropTypes.bool,
	// bool with default value = true
	showMenu: PropTypes.bool,
	showLogin: PropTypes.bool
};

export default SuggestedProducts;
