/**
 * ! Change CustomSpinner by your component name
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

const CustomSpinner = (_props) => {
	var props = _props.properties;

	const ticks = new Date().getTime();
	const componentId = "custom-spinner-" + ticks;

	useEffect(() => {
		// Reload data on loaded state change.
	}, []);

	function setSpinner() {
		return (
			<div
				className={`spinner-border primary-spinner child-spinner-superpose2 ${props.addClass}`}
				role="status"
			></div>
		);
	}
	return (
		<center>
			<div className="m-4 parent-spinner-superpose" key={componentId}>
				{setSpinner()}
			</div>
		</center>
	);
};

CustomSpinner.propTypes = {
	addClass: PropTypes.string	
};

export default CustomSpinner;
