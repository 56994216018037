//*
// ! Replace area by the name of the model
//*
import ApiRouter from "../../routes/application/user-details.js";
import commons from "../common-services-funct.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
const _ServiceName = "user-details";

async function GetAll() {
	var data = null;
	try {
		try {
			data = await commons.GetAll(ApiRouter.GetAll(), true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetAll Axios error", [e]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetAll error", [err]);
	}
	return data;
}
async function GetFilter() {
	// get logged user Id
	var id = global.LOGGED_USER.Id;
	var data = null;
	try {
		try {
			data = await commons.GetFilter(
				ApiRouter.GetFilter() + "?UserId=" + id,
				true
			);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName + " service GetFilter " + id + " Axios error",
				[e]
			);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetFilter " + id + " error", [err]);
	}
	return data;
}
async function GetOne(id) {
	var data = null;
	try {
		try {
			data = await commons.GetOne(ApiRouter.GetOne() + "?UserId=" + id, true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetOne " + id + " Axios error", [
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetOne " + id + " error", [err]);
	}
	return data;
}

async function CreateOne(model) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(ApiRouter.createOne(), model, true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service CreateOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service CreateOne " + "error", [model, err]);
	}
	return data;
}

async function UpdateOne(model) {
	var data = null;
	try {
		try {
			data = await commons.UpdateOne(ApiRouter.PutUpdate(), model, true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service UpdateOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service UpdateOne " + "error", [model, err]);
	}
	return data;
}

async function DeleteOne(model) {
	var data = null;
	try {
		try {
			data = await commons.DeleteOne(ApiRouter.DeleteOne(), model, true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service DeleteOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service DeleteOne " + "error", [model, err]);
	}
	return data;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	CreateOne,
	UpdateOne,
	DeleteOne
};
