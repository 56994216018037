/**
 * ! Change CookieBanner by your component name
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { initTranslations } from "../../../i18n/i18n.js";
import CookieConsent from "react-cookie-consent";
import { useToastContext } from "../../../context/toast-context.js";

const CookieBanner = (_props) => {
	var props = _props.properties;
	const componentId = "CookieBanner-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	const [visibility, setVisibility] = useState(null);
	const { handleShowToast } = useToastContext();

	useEffect(() => {
		componentDidMount();
	}, [visibility]);

	function componentDidMount() {
		try {
			//Loaded
			setVisibility(props.visibility);
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}

	function onError(error) {
		handleShowToast("error", t("common:error"), `Category Card ${error}`);
	}

	return (
		<div className="App">
			<CookieConsent
				key={componentId}
				location="bottom"
				cookieName="menu-app-o2-rgpd"
				expires={999}
				overlay
				visibility={visibility}
				enableDeclineButton
				buttonClasses="btn btn-success border-rounded w-75 "
				declineButtonClasses="btn btn-danger border-rounded w-75"
				containerClasses="bg-primary-gradient d-flex align-items-center w-100"
				overlayStyle={{ zIndex: "3000" }}
				buttonText={t("modals:cookies.agree")}
				declineButtonText={t("modals:cookies.decline")}
				onAccept={() => {
					setVisibility("byCookieValue");
				}}
				onDecline={() => {
					if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError("cookies declined"); }
				}}
			>
				<h3 className="font-bold">{t("modals:cookies.more-info.title")}</h3>
				<p>
					{t("modals:cookies.message")}
					<br />
					<br />
					{t("modals:cookies.more-info.content")}
					<br />
					<a
						className="btn btn-info mt-2  border-rounded "
						data-bs-toggle="modal"
						data-bs-target="#privacy-modal"
					>
						{t("modals:cookies.more-info.privacy-link")}
					</a>
				</p>
			</CookieConsent>
		</div>
	);
};

CookieBanner.propTypes = {
	visibility: PropTypes.string
};

export default CookieBanner;
