const addressTypes = [
	"Home",
	"Work",
	"Main",
	"Other",
	"Billing",
	"Shipping",
	"Mailing",
	"Permanent",
	"Temporary",
	"Custom"
];
// Pays en anglais
const countries = [
	"France",
	// allemagne
	"Germany",
	// espagne
	"Spain",
	// italie
	"Italy",
	// portugal
	"Portugal",
	// belgique
	"Belgium",
	// luxembourg
	"Luxembourg",
	// pays-bas
	"Netherlands",
	// suisse
	"Switzerland",
	// Angleterre
	"England",
	// Ecosse
	"Scotland",
	// Pays de Galles
	"Wales",
	// Irlande du Nord
	"Northern Ireland",
	// Irlande
	"Ireland"
];

export { addressTypes, countries };
