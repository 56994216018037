import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { StrictMode } from "react";
// Context
import { ToastContextProvider } from "./context/toast-context.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import App from "./App";
import Dashboard from "./components/pages/dashboard.js";
import Details from "./components/pages/details.js";
import Home from "./components/pages/home.js";
import Cart from "./components/pages/cart.js";
import UserProfilePage from "./components/pages/users-profile.js";
import UserOrdersPage from "./components/pages/users-orders.js";
// Global vars
import "./data/globals/globals.js";

import "https://kit.fontawesome.com/ead315ea0a.js";
import "./rits-package/stylesheets/style.css";
import "./assets/styles/fonts.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const _companyModel = require("./components/models/company.js");

var cpid = _companyModel.getCompanyDatas().companyId;

var url = window.location.pathname;

// if window.location.pathname doesn't starts with /cpid, then redirect to /cpid
if (!url.startsWith(`/${cpid}`)) {
	processUrl();
}

function processUrl() {
	// using cpid, take the path and insert /cpid in front of it
	var pathname = window.location.pathname;
	if (pathname != "/") {
		// Check if the first part of the url is a number
		var split1 = pathname.split("/")[1];
		var new_path = "";
		if (!isNaN(split1) && split1 != cpid) {
			new_path = pathname.replace("/" + split1, "/" + cpid);
			// remove the first part of the url
		} else {
			new_path = "/" + cpid + pathname;
		}
		// Add new path after root url
		var new_url = window.location.origin + new_path;
		if (isNaN(cpid)) cpid = 0;
		if (cpid == 0) {
			new_url = window.location.origin;
		}
		// redirect to new url
		window.location.href = new_url;
	}
}
function setDefault() {
	if (process.env.REACT_APP_COMPANY_ID != null) {
		return (
			<Dashboard
				properties={{
					pageName: global.cpname,
					trNamespace: "dashboard",
					icon: "fa-buildings",
					translatePageTitle: false,
					showHeaderTooltip: false
				}}
			/>
		);
	} else {
		return <Home />;
	}
}
root.render(
	//<StrictMode>
	<ToastContextProvider>
		<ToastContainer
			stacked
			position="bottom-left"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="light"
		/>
		<BrowserRouter>
			<Routes>
				<Route path={`*`} element={setDefault()} />
				<Route path={`${cpid}/*`} element={<App />} />
				<Route path={`/`} element={setDefault()} />
				<Route path={`/${cpid}/`} element={<App />} />
				<Route
					path={`/${cpid}/cart`}
					element={
						<Cart
							properties={{
								pageName: global.cpname,
								trNamespace: "cart"
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/menu`}
					element={
						<Dashboard
							properties={{
								pageName: global.cpname,
								trNamespace: "dashboard",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/details`}
					element={
						<Details
							properties={{
								pageName: "details",
								trNamespace: "details",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/user-profile`}
					element={
						<UserProfilePage
							properties={{
								pageName: "user-profile",
								trNamespace: "common",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/user-orders`}
					element={
						<UserOrdersPage
							properties={{
								pageName: "user-orders",
								trNamespace: "common",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	</ToastContextProvider>
	//</StrictMode>
);

reportWebVitals();
