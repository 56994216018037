import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomSpinner from "./components/common/shared/custom-spinner.js";
import { setTheme, getTheme } from "./components/models/themes.js";
import { initTranslations } from "./i18n/i18n.js";
const _CompanyModel = require("./components/models/company.js");


function App() {
	const navigate = useNavigate();
	const company = _CompanyModel.getCompanyDatas();
	const gotoDahsboard = () => navigate(`/${company.companyId}/menu`);
	const { t } = initTranslations();

	setTheme(getTheme());

	async function startup() {
		await new Promise((r) => setTimeout(r, 1800));
		loggedIn();
	}

	useEffect(() => {
		document.title = t("index:App.title");
		if (process.env.REACT_APP_COMPANY_ID != null) {
			global.cpname = process.env.REACT_APP_SITE_TITLE;
			global.cplogo = process.env.REACT_APP_CIRCLE_LOGO;
		}
		startup();
	}, []);

	function loggedIn() {
		gotoDahsboard();
	}
	function setFooter() {
		return (
			<>
				<footer className="footer footer-bottom bg-primary txt-primary footer-shadow">
					<div className="container-inline-spaced">
						<p className="nav-link active m-4 mt-0 mb-2">
							<img
								className="img-xs"
								src={require("./rits-package/img/logos/AO2-dark.png")}
							/>
							<span className="trn ">{t("index:Company.brand")}</span>
						</p>
						<ul className="navbar-nav m-4 mt-0 mb-2">
							<li className="nav-item dropdown ">
								<a
									className="nav-link active trn dropdown-toggle"
									href="#"
									id="navbarDropdown"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<span className="trn">{t("index:Legal.title")}</span>
								</a>
								<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
									<li className="nav-link active animate blur">
										<a
											className="dropdown-item  trn"
											data-bs-toggle="modal"
											data-bs-target="#privacyModal"
										>
											{t("index:Privacy.title")}
										</a>
									</li>
									<li className="nav-link active animate blur">
										<a
											className="dropdown-item  trn"
											data-bs-toggle="modal"
											data-bs-target="#cgvModal"
										>
											{t("index:Cgv.title")}
										</a>
									</li>
									<li className="nav-link active animate blur">
										<a
											className="dropdown-item  trn"
											data-bs-toggle="modal"
											data-bs-target="#cguModal"
										>
											{t("index:Cgu.title")}
										</a>
									</li>
									<li className="nav-link active animate blur">
										{" "}
										<a className="dropdown-item  trn" data-id="cookie-consent">
											{t("index:Cookie.title")}
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</footer>
			</>
		);
	}
	return (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: global.cpname,
						trNamespace: "common",
						showSpinner: false
					}}
				/>
				<div className="m-4"></div>
				<h2 className="animate blur  delay-1">
					{process.env.REACT_APP_COMPANY_ID
						? process.env.REACT_APP_SITE_TITLE ?? global.cpname
						: global.cpname}
				</h2>
			</header>
			{setFooter()}
		</div>
	);
}

export default App;
