import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/application/user";

function ValidateLogin() {
	const route = `${baseUrl}/validate-login`;
	return route;
}
function CreateOne() {
	const route = `${baseUrl}/create`;
	return route;
}

export default {
	ValidateLogin,
	CreateOne
};
