import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/datas/category-datas";

function GetAll() {
	const route = `${baseUrl}/`;
	return route;
}
function GetFilter() {
	const route = `${baseUrl}/front-filter`;
	return route;
}
function GetOne() {
	const route = `${baseUrl}/one`;
	return route;
}
function PostCreate() {
	const route = `${baseUrl}/create`;
	return route;
}
function PutUpdate() {
	const route = `${baseUrl}/update`;
	return route;
}
function PutDelete() {
	const route = `${baseUrl}/delete`;
	return route;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	PostCreate,
	PutUpdate,
	PutDelete
};
