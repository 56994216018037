//*
// ! Replace area by the name of the model
//*
import ApiRouter from "../../routes/application/order.js";
import commons from "../common-services-funct.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
const _ServiceName = "order";

async function GetAll() {
	var data = null;
	try {
		try {
			data = await commons.GetAll(ApiRouter.GetAll(), true);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetAll Axios error", [e]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetAll error", [err]);
	}
	return data;
}
async function GetFilter(token) {
	var data = null;
	try {
		try {
			data = await commons.GetFilter(
				ApiRouter.GetFilter() + "?UserId=" + global.LOGGED_USER.Id,
				true,
				token
			);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName +
				" service GetFilter " +
				global.LOGGED_USER.Id +
				" Axios error",
				[e]
			);
		}
	} catch (err) {
		_logger.ERROR(
			_ServiceName + " service GetFilter " + global.LOGGED_USER.Id + " error",
			[err]
		);
	}
	return data;
}
async function GetOne(id, token) {
	var data = null;
	try {
		try {
			data = await commons.GetOne(
				ApiRouter.GetOne() + "?Id=" + id,
				true,
				token
			);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetOne " + id + " Axios error", [
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetOne " + id + " error", [err]);
	}
	return data;
}

async function CreateOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(ApiRouter.createOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service CreateOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service CreateOne " + "error", [model, err]);
	}
	return data;
}

async function UpdateOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.UpdateOne(ApiRouter.UpdateOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service UpdateOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service UpdateOne " + "error", [model, err]);
	}
	return data;
}

async function DeleteOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.DeleteOne(ApiRouter.DeleteOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service DeleteOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service DeleteOne " + "error", [model, err]);
	}
	return data;
}

async function GetClientSecret(id) {
	var data = null;
	try {
		try {
			data = await commons.GetOne(
				ApiRouter.GetClientSecret() + "?CompanyId=" + id,
				true,
				null
			);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetOne " + id + " Axios error", [
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetOne " + id + " error", [err]);
	}
	return data;
}

async function GetPaymentForm(form) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(ApiRouter.GetPaymentForm(), form, false);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName + " service GetPaymentForm " + " Axios error",
				[e]
			);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetPaymentForm " + " error", [err]);
	}
	return data;
}

async function GetPaymentConfirm(form) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(
				ApiRouter.GetPaymentConfirm(),
				form,
				false
			);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName + " service GetPaymentConfirm " + " Axios error",
				[e]
			);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetPaymentConfirm " + " error", [
			err
		]);
	}
	return data;
}

async function GetTimeSlots(form) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(ApiRouter.GetTimeSlots(), form, false);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName + " service GetTimeSlots " + " Axios error",
				[e]
			);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetTimeSlots " + " error", [err]);
	}
	return data;
}

async function serverHours({ openingHours, companyDatas, day, basket }) {
	// if openingHours.open, get actualHours
	var resultHours = [];

	if (openingHours.open) {
		var timeNow = new Date();
		if (day != null) {
			timeNow = new Date(day);
		}
		// if day is today, set now as hour
		var now = new Date();
		if (timeNow.getDate() == now.getDate()) {
			timeNow = now;
		}
		var __form = { CompanyId: companyDatas.company.Id, RefDate: timeNow, Basket: basket };
		var slots = await GetTimeSlots(__form)
		if (slots != null)
			slots = slots.timeSlots;
		else
			slots = [];
		for (var i = 0; i < slots.length; i++) {
			var dt = new Date(timeNow);
			dt.setHours(slots[i].split(":")[0]);
			dt.setMinutes(slots[i].split(":")[1]);
			if (dt >= timeNow) {
				resultHours.push(slots[i]);
			}
		}

		// order resultHours by time
		resultHours = resultHours.sort((a, b) => {
			var aTime = a.split(":");
			var bTime = b.split(":");
			if (aTime[0] < bTime[0]) {
				return -1;
			}
			if (aTime[0] > bTime[0]) {
				return 1;
			}
			if (aTime[1] < bTime[1]) {
				return -1;
			}
			if (aTime[1] > bTime[1]) {
				return 1;
			}
			return 0;
		});
		return resultHours;

	}
}


export default {
	GetAll,
	GetFilter,
	GetOne,
	CreateOne,
	UpdateOne,
	DeleteOne,
	GetClientSecret,
	GetPaymentForm,
	serverHours,
	GetPaymentConfirm,

};
