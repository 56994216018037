/**
 * ! Change CategoryCard by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";

const CategoryCard = (_props) => {
	var props = _props.properties;
	const componentId = "CategoryCard-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	componentDidMount();

	function componentDidMount() {
		try {
			//Loaded
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: props.trNamespace }), `Category Card ${error}`);
	}

	function setCategoryContainer() {
		var img = null;
		var imgClass = "left-inline-card-img";

		var description = null;
		if (
			props.category.CategoryDescription != null &&
			props.category.CategoryDescription != ""
		) {
			description = (
				<div className="card-body m-0 p-0 bg-transparent">
					<div className="container-inline-right m-0 p-0">
						<h6 className=" m-2 mb-0 mt-0 p-0">
							{props.category.CategoryDescription}
						</h6>
					</div>
				</div>
			);
			imgClass = "top-left-inline-card-img";
		}
		if (
			props.category.CategoryImage != null &&
			props.category.CategoryImage != "" &&
			props.category.CategoryImage.toLowerCase() != "null" &&
			!props.category.CategoryImage.startsWith("http")
		) {
			img = (
				<img
					alt={props.category.CategoryName}
					src={`data:image/png;base64,${props.category.CategoryImage}`}
					className={`img-sm ${imgClass} margin-left-xxs m-0 mb-0 mt-0`}
				/>
			);
		}
		return (
			<div
				className={`card ${props.additionalCardClassName ?? "bg-primary-gradient"
					} card-no-padding-margin animate blur delay-${props.count}`}
				key={componentId}
			>
				<div className="card-header bg-transparent card-no-padding-margin   m-0 mb-0 mt-0 p-0 ">
					<div className="container-inline m-0 mb-0 mt-0 p-0">
						{img}
						<div className="text-start m-2 mb-0 mt-0">
							<h2 className="mt-2">{props.category.CategoryName}</h2>
						</div>
					</div>
				</div>
				{description}
			</div>
		);
	}

	return setCategoryContainer();
};

CategoryCard.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	category: PropTypes.object,
	count: PropTypes.number,
	additionalCardClassName: PropTypes.string
};

export default CategoryCard;
