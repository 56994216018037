/**
 * ! Change GuestForm by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import ReactRecaptcha3 from "react-google-recaptcha3";
const _companyModel = require("../../models/company.js");
const _guestModel = require("../../models/guest.js");
const _userModel = require("../../models/user.js");
/**
 * Guest form component 
 * @param {*} _props 
 * @returns 
 */
const GuestForm = (_props) => {
	//! Properties
	var props = _props.properties;
	const componentId = "GuestForm-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const CaptchaKey = "6Ldv6gwqAAAAAEmk2Jq9VFGcdyIotlZITBGCVheH";
	var _cpLocal = _companyModel.getCompanyDatas();
	var guest = _guestModel.checkGuest(_cpLocal.companyId);
	//! Init
	useEffect(() => {
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount 
	 */
	function componentDidMount() {
		try {
			ReactRecaptcha3.init(CaptchaKey).then(() => {
				setLoadedState(true);
				setLoadFunction();
			});
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(e);}
		}
	}
	/**
	 * Sets the load function 
	 */
	function setLoadFunction() {
		try {
			if (guest == null) {
				guest = {
					firstName: "",
					lastName: "",
					email: "",
					phoneNumber: ""
				};
			} else {
				// set guest object to inputs
				document.getElementById("guest_firstName").value = guest.firstName;
				document.getElementById("guest_lastName").value = guest.lastName;
				document.getElementById("guest_email").value = guest.email;
				document.getElementById("guest_phoneNumber").value = guest.phoneNumber;
			}
		} catch {
			setTimeout(() => {
				setLoadFunction();
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`Guest form ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast 
	 * @param {*} message 
	 * @param {*} severity 
	 * @param {*} title 
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	//! Functions
	/**
	 * Submits the guest form 
	 * @returns 
	 */
	async function submitGuest() {
		var form = document.getElementById("guest-form");
		if (form.checkValidity() === false) {
			form.classList.add("was-validated");
			return;
		}
		if (
			!_userModel.checkPhone(document.getElementById("guest_phoneNumber").value)
		) {
			sendToast(t("login:incorrect-phone"), "error", t("common:error"));
			return;
		}
		// serialize form
		var formData = {};
		var elements = form.querySelectorAll("input, select");
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			formData[element.id] = element.value;
		}
		ReactRecaptcha3.getToken().then(
			(token) => {
				formData.token = token;
				// TODO: POST Create account here!
				props.submitFunction();
			},
			(error) => {
				if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(error);}
			}
		);
	}
	/**
	 * Sets the form 
	 * @returns 
	 */
	function setForm() {
		return (
			<form className="m-2 needs-validation" id="guest-form" noValidate>
				<div className="row">
					<div className="col-sm-12 col-md-6">
						<div className="form-group mb-3">
							<label
								htmlFor="guest_firstname"
								className="float-start badge txt-dark"
							>
								{t("firstname.label", { ns: props.trNamespace })}
							</label>
							<input
								type="text"
								className="form-control"
								id="guest_firstName"
								placeholder={t("firstname.placeHolder", {
									ns: props.trNamespace
								})}
								onChange={(e) => {
									guest.firstName = e.target.value;
								}}
								required
							/>
						</div>
					</div>
					<div className="col-sm-12 col-md-6">
						<div className="form-group mb-3">
							<label
								htmlFor="guest_lastName"
								className="float-start badge txt-dark"
							>
								{t("lastname.label", { ns: props.trNamespace })}
							</label>
							<input
								type="text"
								className="form-control"
								id="guest_lastName"
								placeholder={t("lastname.placeHolder", {
									ns: props.trNamespace
								})}
								onChange={(e) => {
									guest.lastName = e.target.value;
								}}
								required
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-6">
						<div className="form-group mb-3">
							<label
								htmlFor="guest_email"
								className="float-start badge txt-dark"
							>
								{t("email.label", { ns: props.trNamespace })}
							</label>
							<input
								type="email"
								className="form-control"
								id="guest_email"
								placeholder={t("email.placeHolder", { ns: props.trNamespace })}
								onChange={(e) => {
									guest.email = e.target.value;
								}}
								required
							/>
						</div>
					</div>
					<div className="col-sm-12 col-md-6">
						<div className="form-group mb-3">
							<label
								htmlFor="guest_phoneNumber"
								className="float-start badge txt-dark"
							>
								{t("phone-number.label", { ns: props.trNamespace })}
							</label>
							<input
								type="number"
								className="form-control"
								id="guest_phoneNumber"
								placeholder={t("phone-number.placeHolder", {
									ns: props.trNamespace
								})}
								onChange={(e) => {
									guest.phoneNumber = e.target.value;
								}}
								minLength={10}
								maxLength={12}
								required
							/>
						</div>
					</div>
				</div>
				<div className="m-3">
					<center>
						<button
							type="button"
							className="btn btn-info"
							onClick={() => {
								submitGuest();
							}}
						>
							<i className="fas fa-face-disguise"></i>&nbsp;
							<span className="m-2 mb-0 mt-0">
								{t("order-without-account.label", { ns: props.trNamespace })}
							</span>
						</button>
					</center>
				</div>
			</form>
		);
	}
	/**
	 * Sets the guest form 
	 * @returns 
	 */
	function setGuestForm() {
		return (
			<div className="card m-4">
				<div className="card-header bg-primary-gradient">
					<div className="container-inline-centered">
						<img
							src={require("../../../rits-package/img/logos/AO2-dark.png")}
							alt="AO2"
							className="img-sm"
						/>
						<h1 className="">
							{t(props.pageName, {
								ns: props.trNamespace
							})}
						</h1>
					</div>
				</div>
				<div className="card-body bg-light p-2">{setForm()}</div>
			</div>
		);
	}
	/**
	 * Rendering the component 
	 */
	return loaded ? (
		<>
			<input type="hidden" id={componentId} />
			{setGuestForm()}
		</>
	) : null;
};

GuestForm.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	submitFunction: PropTypes.func
};

export default GuestForm;
