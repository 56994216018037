//*
// ! Replace area by the name of the model
//*
import ApiRouter from "../../routes/application/login.js";
import commons from "../common-services-funct.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
const _ServiceName = "login";

async function ResetPassword(data) {
	var res = null;
	try {
		try {
			res = await commons.CreateOne(ApiRouter.ResetPassword(), data, false);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetAll Axios error", [e]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetAll error", [err]);
	}
	return res;
}

export default {
	ResetPassword
};
