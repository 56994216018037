/**
 * ! Change ProductCard by your component name
 */

import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import CustomModal from "../shared/custom-modal.js";
import CustomSpinner from "../shared/custom-spinner-small.js";
import MenuProperties from "../../../properties/datas/menu-properties.js";
const _pageProperties = new MenuProperties();
const _model = require("../../models/dashboard.js");
const _basketModel = require("../../models/basket.js");
const _deactivatedModel = require("../../models/deactivated.js");
import { setVegan, setHalal, setHomeMade, setNutriscore, setSpicy, setVege, setAllergens, setDescription, setIngredients, setPromoTag, setQty, setTotalPrice, getQty, getTotalPrice } from "../../helpers/product-card-helper.js";
import React, { useState, useEffect } from "react";

const ProductCard = (_props) => {
	var props = _props.properties;
	const componentId = "ProductCard-" + Math.random().toString(36).substring(7);
	const [loaded, setLoaded] = useState(false);
	const nutriscore = [
		require("../../../assets/img/nutriscore/1.png"),
		require("../../../assets/img/nutriscore/2.png"),
		require("../../../assets/img/nutriscore/3.png"),
		require("../../../assets/img/nutriscore/4.png"),
		require("../../../assets/img/nutriscore/5.png")
	];
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	// Show params

	var selectedVariant = {
		variantName: "",
		variantPrice: ""
	};
	var selectedRemoveIngredients = [];
	var selectedAddIngredients = [];
	var selectedAdditionalProducts = [];
	const [deactivated, setDeactivated] = useState({ opacity: 1 });
	// var selectedProduct = null;

	useEffect(() => {
		//props = _props.properties;
		componentDidMount();
	}, [loaded]);

	function componentDidMount() {
		try {
			if (
				props.item.ProductOptions != null &&
				props.item.ProductOptions.length > 0 &&
				props.item.ProductOptions[0].Deactivated
			) {
				setDeactivated({ opacity: 0.4 });
			}
			setTimeout(() => {
				setLoaded(true);
			}, 100);
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`Product Card ${error}`,
			"error",
			t("common:error")
		);
	}

	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	/**
	 * Set the product header
	 * @param {*} item
	 * @returns
	 */
	function setProductHeader(item) {
		return (
			<div className="row">
				<div className="container-inline-spaced card-sm">
					<h5
						className="app-text-color m-4 mt-0 mb-0"
						style={{ overflowY: "hidden", maxHeight: "100%" }}
					>
						{item.Product}
					</h5>
					<span style={{ minWidth: "110px" }}>
						{setCustomModalAddToCartProperties(item)}
					</span>
				</div>
			</div>
		);
	}
	/**
	 * Set the product card body
	 * @param {*} item
	 * @returns
	 */
	function SetProductCardBody(item) {
		if (
			_model.showContent(
				item,
				props.showProductImage,
				props.showProductDescription,
				props.showIngredients,
				props.canOrder
			)
		) {
			var _class = "col-sm-12 col-md-12 col-lg-12";
			var contentImage = "";
			if (_model.showProductImageFunct(item, props.showProductImage)) {
				_class = "col-sm-12 col-md-12 col-lg-6";
				contentImage = (
					<div className={_class}>
						<center>
							{setProductImage(
								item,
								"img-fluid border-rounded img-lg-responsive"
							)}
						</center>
					</div>
				);
			}
			var ct = null;
			if (props.isPromoted == null || props.isPromoted == false) {
				ct = (
					<div className="row ">
						{contentImage}
						<div className={_class}>
							{setDescription(item, _model, props.showProductDescription)}

							<div className="m-2"></div>
							{setIngredients(item, _model, props.showIngredients, t)}
							<div className="m-2"></div>
						</div>
					</div>
				);
			}
			return (
				<>
					<div className="row  d-flex h-100">
						{ct}
						<div className="horizontal-scroller mt-auto bottom-0 mb-2 mt-0">
							{setHomeMade(item, _model, t)}
							{setSpicy(item, _model, t)}
							{setHalal(item, _model, t)}
							{setVegan(item, _model, t)}
							{setVege(item, _model, t)}
						</div>
					</div>
				</>
			);
		}
	}
	/**
	 * Set the product image
	 * @param {*} item
	 * @param {*} _class
	 * @returns
	 */
	function setProductImage(item, _class) {
		if (_class == null) _class = "";
		if (_model.showProductImageFunct(item, props.showProductImage)) {
			if (
				item.ProductImage != null &&
				item.ProductImage != "" &&
				!item.ProductImage.toLowerCase().startsWith("http")
			) {
				return (
					<div className={`parent-superpose ${_class}`}>
						<img
							alt={item.Product}
							src={`data:image/png;base64,${item.ProductImage}`}
							className={`child-superpose1 ${_class}`}
						/>
						{setPromoTag(item, false)}
					</div>
				);
			}
			return null;
			// else {
			// 	var text = item.Product;
			// 	var canvas = _model.draw(text);
			// 	var src = canvas.toDataURL("image/png");
			// 	return <img alt={item.Product} className={_class} src={src} />;
			// }
		}
	}
	/**
	 * Set the product card footer
	 * @param {*} item
	 * @returns
	 */
	function setProductCardFooter(item) {
		return (
			<>
				<div className="row">
					<div className="horizontal-scroller ">{setPrices(item)}</div>
				</div>
				{/* <div className="row">
					<div className="container-inline-right">
						{setCustomModalAddToCartProperties(item)}
					</div>
				</div> */}
			</>
		);
	}
	function setPrices(item) {
		return setBtnPrices(item, true);
	}
	function setBtnPrices(item, iscard = false) {
		var variants = [];
		if (item.ProductComplements != null && item.ProductComplements.length > 0) {
			var productComplement = item.ProductComplements[0];
			if (
				productComplement.ProductVariant != null &&
				productComplement.ProductVariant.length > 0
			) {
				// remove duplicates from productComplement.ProductVariant
				productComplement.ProductVariant =
					productComplement.ProductVariant.filter(
						(v, i, a) =>
							a.findIndex((t) => t.VariantName === v.VariantName) === i
					);

				for (var i = 0; i < productComplement.ProductVariant.length; i++) {
					var element = productComplement.ProductVariant[i];
					if (element.deactivated ?? false) {
						// push button outline danger with text out of stock
						var deactivatedItem = { id: item.Id, variant: element.VariantName };
						_deactivatedModel.addDeactivated(item.CompanyId, deactivatedItem);

						variants.push(
							<button
								className={`btn btn-sm btn-outline-danger font-bold m-1`}
								disabled={true}
								key={`varbtn-${item.Id}-${element.VariantName}-false`}
							>
								<span
									data-variantname={element.VariantName}
									data-variantprice={element.VariantPrice}
								>
									<span className="mt-1 mb-1">🚫 {element.VariantName}</span>
									<span className="mt-1 mb-1">
										&nbsp;{element.VariantPrice}{" "}
										{props.companyDatas.company.CompanyParams.Currency ?? "€"}
									</span>
								</span>
							</button>
						);
						continue;
					} else {
						deactivatedItem = { id: item.Id, variant: element.VariantName };
						_deactivatedModel.removeDeactivated(
							item.CompanyId,
							deactivatedItem
						);
					}
					if (!element.visible ?? true) {
						continue;
					}
					// set variantPrice decimal with 2 digits after comma
					var variantPrice = parseFloat(element.VariantPrice).toFixed(2);
					// check if variant price is nan
					if (!isNaN(variantPrice)) {
						var itemId = `varbtn-${item.Id}-${element.VariantName}-${iscard ? "-false" : ""
							}`;
						// if key is not in variants array push it

						variants.push(
							<button
								className={`btn btn-sm btn-secondary-light font-bold m-1 ${iscard ? "disabled" : ""
									}`}
								onClick={(e) => setSelectedVariant(e)}
								id={itemId}
								key={itemId}
								data-btn-type={`${iscard ? "" : "variant-type"}`}
							>
								<span
									data-variantname={element.VariantName}
									data-variantprice={variantPrice}
								>
									<span className="mt-1 mb-1">{element.VariantName}</span>
									<span className="mt-1 mb-1">
										&nbsp;{variantPrice}{" "}
										{props.companyDatas.company.CompanyParams.Currency ?? "€"}
									</span>
								</span>
							</button>
						);
					}
				}
			}
		}
		var priceTag = item.PriceTag;
		if (priceTag == null || priceTag == "")
			priceTag = t("classic", { ns: "menu" });
		if (variants == null || variants.length == 0) {
			priceTag = t("price", { ns: "menu" });
		}

		var priceHeader = <span className="mt-1 mb-1">{priceTag}</span>;
		var price = null;
		if (variants.length > 0) {
			price = (
				<button
					className={`btn btn-sm btn-secondary-light font-bold ${iscard ? "disabled" : "btn-info"
						}
					 m-1`}
					onClick={(e) => setSelectedVariant(e)}
					id={`varbtn-${item.Id}-price${iscard ? "-false" : ""}`}
					key={`varbtn-${item.Id}-price${iscard ? "-false" : ""}`}
					data-btn-type={`${iscard ? "" : "variant-type"}`}
				>
					<span data-variantname={priceTag} data-variantprice={item.Price}>
						{priceHeader}
						<span className="mt-1 mb-1">
							&nbsp;{item.Price}{" "}
							{props.companyDatas.company.CompanyParams.Currency ?? "€"}
						</span>
					</span>
				</button>
			);
		} else {
			price = (
				<button
					className={`btn btn-sm btn-secondary-light font-bold ${iscard ? "disabled" : "btn-info"
						} m-1`}
					disabled={true}
					id={`varbtn-${item.Id}-price${iscard ? "-false" : ""}`}
					key={`varbtn-${item.Id}-price${iscard ? "-false" : ""}`}
				>
					<span data-variantname={priceTag} data-variantprice={item.Price}>
						{priceHeader}
						<span className="mt-1 mb-1">
							&nbsp;{item.Price}{" "}
							{props.companyDatas.company.CompanyParams.Currency ?? "€"}
						</span>
					</span>
				</button>
			);
		}

		selectedVariant = {
			variantName: priceTag,
			variantPrice: item.Price
		};

		//insert price at index 0 of variants
		variants.unshift(price);

		// Return price first and all variants
		return variants;
	}
	function setSelectedVariant(e) {
		var el = document.getElementById(e.currentTarget.id);
		if (el == null) return;
		// Get first span in el
		var span = el.getElementsByTagName("span")[0];
		var price = span.getAttribute("data-variantprice");
		var name = span.getAttribute("data-variantname");
		selectedVariant = {
			variantName: name,
			variantPrice: price
		};
		// Get all buttons with data-btn-type="variant-type"
		var btnList = document.querySelectorAll(
			`button[data-btn-type="variant-type"]`
		);
		// for each btn in btn list remove class btn-outline-secondary and btn-secondary
		btnList.forEach((_btn) => {
			// if btn has class "btn-outline-secondary" continue
			_btn.classList.remove("btn-info");
			_btn.classList.add("btn-outline-light");
		});
		el.classList.remove("btn-outline-light");
		el.classList.add("btn-info");
		setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts, false);
	}
	/**
	 * Set order steps
	 * @param {*} item
	 */
	function setNextStep(item) {
		setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts);
		var btn = document.getElementById(`add-to-cart-button-id-${item.Id}`);
		// Get number value of attr	dataSteps
		var steps = parseInt(btn.getAttribute("data_steps"));
		// vet number value of attr						dataActualStep="0"
		var actualStep = parseInt(btn.getAttribute("data_actual_step"));
		actualStep++;

		if (steps - 1 >= actualStep) {
			// Set attr data-actual-step
			btn.setAttribute("data_actual_step", actualStep);
			if (actualStep == steps - 1) {
				btn.className = "btn btn-success  p-2  p-component";
				btn.innerHTML = `<span class=" p-2 btn-icon p-c btn-icon-left fa-solid fa-check" data-pc-section="icon" aria-hidden="true"></span><span class="btn-label p-c" data-pc-section="label">${t(
					"validate",
					{ ns: "common" }
				)}</span>`;
			} else {
				btn.className = "btn btn-info  p-2  btn p-component";
				btn.innerHTML = `<span class=" p-2 btn-icon p-c btn-icon-left fa-solid fa-square-right" data-pc-section="icon" aria-hidden="true"></span><span class="btn-label p-c" data-pc-section="label">${t(
					"next",
					{ ns: "common" }
				)}</span>`;
			}
			// Get element by id `acc-add-to-cart-button-id-${item.Id}-${tabId}`
			var acc = document.getElementById(
				`acc-add-to-cart-button-id-${item.Id}-${actualStep}`
			);
			if (acc != null) {
				acc.style.display = "block";
				var scroller = document.getElementById(`acc-scroller-${item.Id}-0`);
				// get acc position:
				var accPos = acc.offsetTop - 100;
				// scroll to bottom
				scroller.scrollTo(0, accPos);
			}
			// get element `acc-scroller-${item.Id}-0`
		} else {
			// Get dateTime now to miliseconds

			var addedProduct = {
				BasketId: Number(new Date()),
				Product: item.Product,
				ProductDataId: item.Id,
				Variant: selectedVariant,
				AddedIngredients: selectedAddIngredients,
				RemovedIngredients: selectedRemoveIngredients,
				AdditionalProducts: selectedAdditionalProducts,
				TotalUnitPrice: getTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts),
				TotalQty: getQty(),
				TotalPrice: (getTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts) * getQty()).toFixed(2)
			};
			_basketModel.setBasketPriceAndQty({
				addedProduct: addedProduct,
				currency: props.companyDatas.company.CompanyParams.Currency,
				cpid: props.companyDatas.company.CompanyId
			});
			// get button by data-id = close-modal-${props.componentId}
			var modalBtn = document.querySelector(
				`[data-id="close-modal-modal-product-${item.Id}"]`
			);
			// click on modal button
			modalBtn.click();
			var toastMsg = `${getQty()} x ${item.Product}`;

			if (
				selectedVariant != null &&
				selectedVariant.variantName != t("price", { ns: "menu" })
			) {
				toastMsg += ` ${selectedVariant.variantName}`;
			}
			sendToast(
				toastMsg,
				"",
				getQty() > 1
					? t("common:cart.products-added")
					: t("common:cart.product-added")
			);
		}
	}

	var needCheck = [];
	/**
	 * Set the product modal content
	 * @param {*} item
	 * @returns
	 */
	function setProductModalContent(item) {
		needCheck = [];
		var _class = "col-sm-12 col-md-12 col-lg-12 h-100";
		var contentImage = "";
		if (_model.showProductImageFunct(item, props.showProductImage)) {
			_class = "col-sm-12 col-md-12 col-lg-6 h-100";
			contentImage = (
				<div className={`${_class}`}>
					<center>
						{setProductImage(item, "border-rounded img-xl-responsive")}
					</center>
				</div>
			);
		}
		var tabId = 1;
		var canRemove = setRemoveIngredientProduct(item, tabId);
		if (canRemove) tabId++;
		var canAdd = setAddIngredientsToProduct(item, tabId);
		if (canAdd) tabId++;
		var servedWith = setAdditionalProductToProduct(item, tabId);
		if (servedWith) tabId++;

		return (
			<div
				className="card-body  h-100"
				data-bs-spy="scroll"
				style={{ overflowY: "scroll", overflowX: "hidden" }}
				id={`acc-scroller-${item.Id}-0`}
			>
				<div className="m-2">
					<div id={`acc-add-to-cart-button-id-${item.Id}-0`}>
						<div className="mt-0">
							<div className="row h-100">
								{contentImage}
								<div className={`${_class} align-middle`}>
									{setDescription(item, _model, props.showProductDescription)}
									<div className="horizontal-scroller">
										{setHomeMade(item, _model, t)}
										{setSpicy(item, _model, t)}
										{setHalal(item, _model, t)}
										{setVegan(item, _model, t)}
										{setVege(item, _model, t)}
									</div>
									{setIngredients(item, _model, props.showIngredients, t)}
									<div className=" align-middle">{setAllergens(item, _model, props.showAllergens, t)}</div>
									<div className="container-inline">{setNutriscore(item, _model, props, nutriscore)}</div>
								</div>
							</div>
						</div>
						<div className="m-4"></div>
						{canRemove}
						{canAdd}
						{servedWith}
					</div>
					<div className="row">
						<div className="horizontal-scroller-right">
							{setBtnPrices(item, false)}
						</div>
					</div>

					<div
						className="row mt-2 mb-0 b-0"
						id="total-price-div"
						style={{ display: "none" }}
					>
						<hr />
						<div className="container-inline-right font-bold ">
							<span>{t("common:total")} : &nbsp;</span>
							<span id="total-price">0.00</span>
							<span>
								&nbsp;
								{props.companyDatas.company.CompanyParams.Currency ?? "€"}
							</span>
						</div>
						<div className="mb-2 mt-2"></div>
						<div className="container-inline-right font-bold ">
							<button
								className="btn btn-outline-primary  btn-sm btn-round"
								onClick={() => setQty(false, selectedVariant, selectedAddIngredients, selectedAdditionalProducts)}
							>
								<i className="fa fa-solid fa-minus"></i>
							</button>
							<div className="m-2">
								<span id="total-qty">1</span>
							</div>
							<button
								className="btn btn-outline-primary btn-sm btn-round"
								onClick={() => setQty(true, selectedVariant, selectedAddIngredients, selectedAdditionalProducts)}
							>
								<i className="fa fa-solid fa-plus"></i>
							</button>
						</div>
					</div>
					<div
						className="m-4 scroll-bottom-anchor"
						id={`acc-bottom-add-to-cart-button-id-${item.Id}-end`}
					></div>
				</div>
			</div>
		);
	}

	function setAddIngredientsToProduct(item, tabId) {
		var toAdd = setAddIngredients(item);
		if (toAdd.length == 0) return null;
		return (
			<div
				id={`acc-add-to-cart-button-id-${item.Id}-${tabId}`}
				style={{ display: "none" }}
			>
				<div
					className={`card bg-primary-gradient card-no-padding-margin card-sm animate glow`}
				>
					<div className="card-header card-no-padding-margin">
						<div className="container-inline  m-0 mb-0 mt-0 p-0">
							<div className="text-start m-2 mb-0 mt-0">
								<h2 className="mt-2">{t("to-add", { ns: "menu" })}</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="m-2"></div>
				<div className="col-sm-12  animate glow delay-1">{toAdd}</div>
				<div className="m-2"></div>
			</div>
		);
	}

	function setRemoveIngredientProduct(item, tabId) {
		var toRemove = setRemoveIngredients(item);
		if (toRemove.length == 0) return null;
		return (
			<div
				id={`acc-add-to-cart-button-id-${item.Id}-${tabId}`}
				style={{ display: "none" }}
			>
				<div
					className={`card bg-primary-gradient card-no-padding-margin card-sm animate glow`}
				>
					<div className="card-header card-no-padding-margin ">
						<div className="container-inline  m-0 mb-0 mt-0 p-0">
							<div className="text-start m-2 mb-0 mt-0">
								<h2 className="mt-2">{t("to-remove", { ns: "menu" })}</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="m-2"></div>
				<div className="col-sm-12  animate glow delay-1">{toRemove}</div>
				<div className="m-2"></div>
			</div>
		);
	}

	function setAdditionalProductToProduct(item, tabId) {
		var lst = setAdditionalProducts(item);
		if (lst.length == 0) return null;
		return (
			<div
				id={`acc-add-to-cart-button-id-${item.Id}-${tabId}`}
				style={{ display: "none" }}
			>
				<div
					className={`card bg-primary-gradient card-no-padding-margin card-sm animate glow`}
				>
					<div className="card-header card-no-padding-margin ">
						<div className="container-inline  m-0 mb-0 mt-0 p-0">
							<div className="text-start m-2 mb-0 mt-0">
								<h2 className="mt-2">
									{t("additional-products", { ns: "menu" })}
								</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="m-2"></div>
				<div className="col-sm-12  animate glow delay-1 ">{lst}</div>
				<div className="m-2"></div>
			</div>
		);
	}

	function setAdditionalProducts(item) {
		var lst = [];
		var stats = _model.hasServedWithProducts(item);
		if (stats.has) {
			for (
				var i = 0;
				i < item.ProductComplements[0].ProductDataOptions.ServedWith.length;
				i++
			) {
				var items = item.ProductComplements[0].ProductDataOptions.ServedWith[i];
				var category = items.Category[0];
				// Draw the category
				lst.push(
					<div className="row" key={category.Id}>
						<div className="col-sm-12">
							<div className="m-2"></div>
							<h3>{category.CategoryName}</h3>
							<hr></hr>
							<div className="m-2"></div>
						</div>
					</div>
				);
				// Foreach products in items.ServedWithProducts add a checkbox
				var products = items.ServedWithProducts;
				for (var j = 0; j < products.length; j++) {
					if (
						products[j].ProductOptions != null &&
						products[j].ProductOptions.length > 0 &&
						products[j].ProductOptions[0].Deactivated
					) {
						lst.push(
							<div className="row" key={products[j].Id}>
								<div className="col-sm-12">
									<div className="form-check m-2">
										<input
											className="form-check-input checkbox-xl"
											type="checkbox"
											value=""
											data-type={dttype}
											data-productid={products[j].Id}
											data-productvalue={products[j].AddProductPrice}
											data-productname={products[j].Product}
											id={productId}
											disabled={true}
											style={{ opacity: 0.5 }}
										/>
										<label
											className="form-check-label text-danger"
											htmlFor={`flexCheckDefault-${products[j].Id}`}
										>
											{products[j].Product} ({t("common:out-of-stock")})
										</label>
									</div>
								</div>
							</div>
						);
						continue;
					}

					// Set AddProductPrice
					var addProductPrice =
						parseFloat(products[j].AddProductPrice ?? 0) > 0 // eslint-disable-line no-mixed-spaces-and-tabs
							? // eslint-disable-line no-mixed-spaces-and-tabs
							" ( +" + // eslint-disable-line no-mixed-spaces-and-tabs
							products[j].AddProductPrice +
							" " + // eslint-disable-line no-mixed-spaces-and-tabs
							(props.companyDatas.company.CompanyParams.Currency ?? "€") + // eslint-disable-line no-mixed-spaces-and-tabs
							" )" // eslint-disable-line no-mixed-spaces-and-tabs
							: ""; // eslint-disable-line no-mixed-spaces-and-tabs
					var dttype = `served-with-${item.Id}-${products[j].CategoryId}`;
					var productId = `flexCheckDefault-${products[j].Id}`;
					// Check if needCheck contains dttype
					if (!needCheck.includes(dttype)) {
						needCheck.push(dttype);
					}
					lst.push(
						<div className="row" key={products[j].Id}>
							<div className="col-sm-12">
								<div className="form-check m-2">
									<input
										className="form-check-input checkbox-xl"
										type="checkbox"
										value=""
										data-type={dttype}
										data-productid={products[j].Id}
										data-productvalue={products[j].AddProductPrice}
										data-productname={products[j].Product}
										id={productId}
										onClick={(e) => {
											var inputs = document.querySelectorAll(
												`input[data-type="${e.target.dataset.type}"]`
											);
											var thisInput = e.currentTarget;
											// get checked value of thisInput
											var checked = thisInput.checked;
											if (checked) {
												//uncheck all inputs except this one
												inputs.forEach((input) => {
													input.checked = false;
												});
												thisInput.checked = true;
											}
											selectAdditionalProducts(e);
										}}
									/>
									<label
										className="form-check-label"
										htmlFor={`flexCheckDefault-${products[j].Id}`}
									>
										{products[j].Product} {addProductPrice}
									</label>
								</div>
							</div>
						</div>
					);
				}
			}
		}
		return lst;
	}

	function selectAdditionalProducts(e) {
		var inputs = document.querySelectorAll(
			`input[data-type="${e.target.dataset.type}"]`
		);
		for (var i = 0; i < inputs.length; i++) {
			var productId = inputs[i].getAttribute("data-productid");
			var productPrice = inputs[i].getAttribute("data-productvalue"); // Products are included in the price
			var productName = inputs[i].getAttribute("data-productname");
			if (inputs[i].checked) {
				selectedAdditionalProducts.push({
					productId: productId,
					productPrice: productPrice,
					productName: productName
				});
			} else {
				selectedAdditionalProducts = selectedAdditionalProducts.filter(
					(item) => item.productId !== productId
				);
			}
		}
		setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts);
	}

	function setAddIngredients(item) {
		var lst = [];
		var stats = _model.hasCanAddIngredients(item);
		if (stats.has) {
			for (
				var i = 0;
				i <
				item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients.length;
				i++
			) {
				var ingredientPrice =
					parseFloat(
						item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients[i] // eslint-disable-line no-mixed-spaces-and-tabs
							.AddPrice ?? 0
					) > 0 // eslint-disable-line no-mixed-spaces-and-tabs
						? " ( +" + // eslint-disable-line no-mixed-spaces-and-tabs
						item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients[
							i
						].AddPrice +
						" " + // eslint-disable-line no-mixed-spaces-and-tabs
						(props.companyDatas.company.CompanyParams.Currency ?? "€") + // eslint-disable-line no-mixed-spaces-and-tabs
						" )" // eslint-disable-line no-mixed-spaces-and-tabs
						: ""; // eslint-disable-line no-mixed-spaces-and-tabs
				lst.push(
					<div
						className="row"
						key={
							item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients[
								i
							].Id
						}
					>
						<div className="col-sm-12">
							<div className="form-check  m-2">
								<input
									className="form-check-input checkbox-xl"
									type="checkbox"
									value=""
									data-ingredienttype={`add-${item.Id}`}
									data-ingredientvalue={
										item.ProductComplements[0].ProductDataOptions.CanAdd // eslint-disable-line no-mixed-spaces-and-tabs
											.Ingredients[i].AddPrice ?? 0
									}
									data-ingredientname={
										item.ProductComplements[0].ProductDataOptions.CanAdd
											.Ingredients[i].IngredientName
									}
									data-ingredientid={
										item.ProductComplements[0].ProductDataOptions.CanAdd
											.Ingredients[i].Id
									}
									id={`flexCheckDefault-${item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients[i].Id}`}
									onChange={(e) => {
										selectIngredient(e, true);
									}}
								/>
								<label
									className="form-check-label"
									htmlFor={`flexCheckDefault-${item.ProductComplements[0].ProductDataOptions.CanAdd.Ingredients[i].Id}`}
								>
									{
										item.ProductComplements[0].ProductDataOptions.CanAdd
											.Ingredients[i].IngredientName
									}{" "}
									{ingredientPrice}
								</label>
							</div>
						</div>
					</div>
				);
			}
		}
		return lst;
	}

	function setRemoveIngredients(item) {
		var lst = [];
		var stats = _model.hasCanRemoveIngredients(item);
		if (stats.has) {
			for (
				var i = 0;
				i <
				item.ProductComplements[0].ProductDataOptions.CanRemove.Ingredients
					.length;
				i++
			) {
				lst.push(
					<div
						className="row"
						key={
							item.ProductComplements[0].ProductDataOptions.CanRemove
								.Ingredients[i].Id
						}
					>
						<div className="col-sm-12">
							<div className="form-check  m-2">
								<input
									className="form-check-input checkbox-xl"
									type="checkbox"
									value=""
									data-type={`remove-${item.Id}`}
									data-ingredientvalue="0"
									data-ingredientname={
										item.ProductComplements[0].ProductDataOptions.CanRemove
											.Ingredients[i].IngredientName
									}
									data-ingredientid={
										item.ProductComplements[0].ProductDataOptions.CanRemove
											.Ingredients[i].Id
									}
									id={`flexCheckDefault-${item.ProductComplements[0].ProductDataOptions.CanRemove.Ingredients[i].Id}`}
									onChange={(e) => {

										selectIngredient(e, false);


									}}
								/>
								<label
									className="form-check-label"
									htmlFor={`flexCheckDefault-${item.ProductComplements[0].ProductDataOptions.CanRemove.Ingredients[i].Id}`}
								>
									{
										item.ProductComplements[0].ProductDataOptions.CanRemove
											.Ingredients[i].IngredientName
									}
								</label>
							</div>
						</div>
					</div>
				);
			}
		}
		return lst;
	}
	/**
	 * Select ingredient from add or remove list
	 * @param {*} e
	 * @param {*} add
	 */
	function selectIngredient(e, add) {
		var element = e.currentTarget;
		// check if e is checked
		var checked = element.checked;
		// get data-ingredientvalue
		var ingredientValue = element.getAttribute("data-ingredientvalue");
		var ingredientName = element.getAttribute("data-ingredientname");
		var ingredientId = element.getAttribute("data-ingredientid");
		if (add) {
			if (checked) {
				// if selectedAddIngredients not contains ingredientId add {id: ingredientId, name: ingredientName, value: ingredientValue}
				if (!selectedAddIngredients.some((el) => el.id === ingredientId)) {
					selectedAddIngredients.push({
						id: ingredientId,
						name: ingredientName,
						value: ingredientValue
					});
				}
			} else {
				selectedAddIngredients = selectedAddIngredients.filter(
					(el) => el.id !== ingredientId
				);
			}
		} else {
			if (checked) {
				// if selectedRemoveIngredients not contains ingredientId add {id: ingredientId, name: ingredientName, value: ingredientValue}
				if (!selectedRemoveIngredients.some((el) => el.id === ingredientId)) {
					selectedRemoveIngredients.push({
						id: ingredientId,
						name: ingredientName,
						value: ingredientValue
					});
				}
			} else {
				// if selectedRemoveIngredients contains ingredientId remove it
				if (selectedRemoveIngredients.some((el) => el.id === ingredientId)) {
					selectedRemoveIngredients = selectedRemoveIngredients.filter(
						(el) => el.id !== ingredientId
					);
				}
			}
		}
		setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts);
	}
	/**
	 * Set the product modal
	 * @param {*} item
	 * @returns
	 */
	function setCustomModalAddToCartProperties(item) {
		if (props == undefined) {
			props = _props.properties;
		}

		if (item.ProductOptions == null || item.ProductOptions.length == 0)
			return null;
		else {
			if (item.ProductOptions[0].Deactivated) {
				var deactivatedItem = { id: item.Id, variant: "" };
				_deactivatedModel.addDeactivated(item.CompanyId, deactivatedItem);
				// Red button with out of stock message
				return (
					<button className="btn btn-danger btn-block m-2" disabled>
						{t("common:out-of-stock")}
					</button>
				);
			} else {
				deactivatedItem = { id: item.Id, variant: "" };
				_deactivatedModel.removeDeactivated(item.CompanyId, deactivatedItem);
			}
		}
		if (
			_model.showContent(
				item,
				props.showProductImage,
				props.showProductDescription,
				props.showIngredients,
				props.canOrder
			)
		) {
			var dataSteps = 1;
			if (_model.hasCanAddIngredients(item).has) dataSteps++;
			if (_model.hasCanRemoveIngredients(item).has) dataSteps++;
			if (_model.hasServedWithProducts(item).has) dataSteps++;

			if (dataSteps == 1) {
				// Consider + 1 to show the qty widget
				dataSteps++;
			}

			var props = _pageProperties.AddToCartModalProperties(
				[
					() => {
						setNextStep(item);
					}
				],
				setProductModalContent(item),
				item,
				dataSteps,
				props.canOrder,
				props.additionalModalButtonClassName ?? "bg-primary"
			);
			props.componentId = `modal-product-${item.Id}`;
			props.componentName = item.Product;
			return (
				<CustomModal id={`modal-product-info-${item.Id}`} properties={props} />
			);
		}
	}
	function setBody() {
		return (
			<div
				className={`card-body ${props.additionalBodyClassName ?? "bg-light-dark"
					}`}
			>
				{SetProductCardBody(props.item)}
			</div>
		);
	}

	return loaded ? (
		<div
			className={`col-xs-12 col-md-6 col-lg-4 col-xxl-3 p-2  animate spin delay-${props.count}`}
			key={componentId}
			id={props.item.Id}
			style={deactivated}
		>
			<div className=" card card-product h-100 app-lighter-bg-color">
				<div
					className={`card-header card-sm  sticky-top-card-header ${props.additionalHeaderClassName ?? "bg-primary-gradient"
						}`}
				>
					{setProductHeader(props.item)}
				</div>
				{setBody()}
				<div
					className={`card-footer ${props.additionalFooterClassName ?? "bg-secondary"
						}`}
				>
					{setProductCardFooter(props.item)}
				</div>
			</div>
		</div>
	) : (
		<div
			className={`col-xs-12 col-md-6 col-lg-4 col-xxl-3 p-2 animate spin delay-${props.count}`}
			style={{ height: "300px" }}
			key={componentId}
			id={props.item.Id}
		>
			<div className=" card h-100 app-lighter-bg-color">
				<CustomSpinner
					properties={{
						addClass: "spin-border-l"
					}}
				></CustomSpinner>
			</div>
		</div>
	);
};

ProductCard.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	item: PropTypes.object,
	count: PropTypes.number,
	showAllergens: PropTypes.bool,
	showIngredients: PropTypes.bool,
	showNutriscore: PropTypes.bool,
	showProductDescription: PropTypes.bool,
	showProductImage: PropTypes.bool,
	canOrder: PropTypes.bool,
	companyDatas: PropTypes.object,
	additionalHeaderClassName: PropTypes.string,
	additionalBodyClassName: PropTypes.string,
	additionalFooterClassName: PropTypes.string,
	additionalModalButtonClassName: PropTypes.string,
	isPromoted: PropTypes.bool
};

export default ProductCard;
