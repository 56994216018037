/**
 * ! Change CreateAccount by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { countries, addressTypes } from "../../../config/variables.js";
import ReactRecaptcha3 from "react-google-recaptcha3";
import userService from "../../../data/services/datas/user.js";
import { loadUser } from "../../../data/services/load-data-service.js";
/**
 * Create account component
 * @param {*} _props
 * @returns
 */
const CreateAccount = (_props) => {
	//! Properties
	var props = _props.properties;
	const componentId =
		"CreateAccount-" + Math.random().toString(36).substring(7);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [loaded, setLoadedState] = useState(false);
	const CaptchaKey = "6Ldv6gwqAAAAAEmk2Jq9VFGcdyIotlZITBGCVheH";
	const _userModel = require("../../models/user.js");
	//! INIT
	useEffect(() => {
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount
	 */
	function componentDidMount() {
		try {
			ReactRecaptcha3.init(CaptchaKey).then(() => {
				setLoadedState(true);
			});
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(e);}
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`create Account ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	//! FUNCTIONS
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	/**
	 * Submits the account form
	 * @returns
	 */
	async function submitAccount() {
		var form = document.getElementById("create-account-form");
		if (form.checkValidity() === false) {
			form.classList.add("was-validated");
			return;
		}
		// Check passwords rules
		var password = document.getElementById("ipassword").value;
		var password2 = document.getElementById("ipassword2").value;
		if (!_userModel.checkPasswords(password)) {
			sendToast(
				t("login:password-reset.password-rules"),
				"error",
				t("common:error")
			);
			return;
		}
		if (password != password2) {
			sendToast(
				t("login:password-reset.password-not-match"),
				"error",
				t("common:error")
			);
			return;
		}
		if (!_userModel.checkPhone(document.getElementById("iphone").value)) {
			sendToast(t("login:incorrect-phone"), "error", t("common:error"));
			return;
		}
		// Get submit button and disable it and add spinner
		var submitBtn = document.getElementById("submit-account");
		submitBtn.disabled = true;
		submitBtn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`;
		// serialize form
		var formData = {};
		var elements = form.querySelectorAll("input, select");
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			formData[element.id] = element.value;
		}
		ReactRecaptcha3.getToken().then(
			(token) => {
				formData.token = token;
				// TODO: POST Create account here!
				userService.CheckUserLogin(formData).then((response) => {
					if (
						response != null &&
						response.exists != null &&
						response.exists.exists
					) {
						sendToast(
							t("login:user-exists.message"),
							"error",
							t("common:error")
						);
						// Set init content of submit button and enable it
						submitBtn.disabled = false;
						submitBtn.innerHTML = `<i class="fa fas fa-user-plus"></i>&nbsp;<span class="m-2 mb-0 mt-0">${t(
							"login:submit-account.label"
						)}</span>`;
						return;
					} else {
						// Create user:
						// rmove token from formData
						delete formData.token;
						// Set formData.ibirthDate to Date UTC, with local day month and year
						var date = new Date(formData.ibirthDate);
						formData.ibirthDate = new Date(
							Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
						);
						userService.CreateUserLogin(formData).then(async (response) => {
							if (response.created) {
								sendToast("User created", "success", "Success");
								loadUser(formData.iemail, formData.ipassword).then((data) => {
									if (data == true) {
										_userModel.getSetUser(global.LOGGED_USER);
										props.submitFunction();
									} else {
										sendToast(
											t("login:errors.incorrect"),
											"error",
											t("common:error")
										);
									}
								});
							} else {
								sendToast("User not created", "error", "Error");
								// Set init content of submit button and enable it
								submitBtn.disabled = false;
								submitBtn.innerHTML = `<i class="fa fas fa-user-plus"></i>&nbsp;<span class="m-2 mb-0 mt-0">${t(
									"login:submit-account.label"
								)}</span>`;
							}
						});
					}
				});
			},
			//props.submitFunction();
			(error) => {
				if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(error);}
				submitBtn.disabled = false;
				submitBtn.innerHTML = `<i class="fa fas fa-user-plus"></i>&nbsp;<span class="m-2 mb-0 mt-0">${t(
					"login:submit-account.label"
				)}</span>`;
			}
		);
	}
	/**
	 * Sets the account form
	 * @returns
	 */
	function setAccountForm() {
		var cOptions = countries.map((item, count) => {
			return (
				<option key={count} value={item}>
					{item}
				</option>
			);
		});
		var atOptions = addressTypes.map((item, count) => {
			return (
				<option key={count} value={item}>
					{item}
				</option>
			);
		});
		return (
			<div className="card m-4">
				<div className="card-header  bg-primary-gradient">
					<div className="container-inline-centered">
						<img
							src={require("../../../rits-package/img/logos/AO2-dark.png")}
							alt="AO2"
							className="img-sm"
						/>
						<h1 className="">
							{t(props.pageName, {
								ns: props.trNamespace
							})}
						</h1>
					</div>
				</div>
				<div className="card-body bg-light">
					<form
						className="row g-3 needs-validation"
						noValidate
						id="create-account-form"
					>
						<table className="table table-bordered w-100 no-border">
							<thead className="table-info">
								<tr style={{ height: "10px" }}>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iemail"
										>
											{t("login:email.label")}*
										</label>
										<input
											className="form-control"
											type="email"
											id="iemail"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="ipassword"
										>
											{t("login:password.label")}*
										</label>
										<input
											className="form-control"
											type="password"
											id="ipassword"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="ipassword2"
										>
											{t("login:password-repeat.label")}*
										</label>
										<input
											className="form-control"
											type="password"
											id="ipassword2"
											required
										/>
									</td>
								</tr>
								<tr>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="ifirstname"
										>
											{t("login:firstname.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="ifirstname"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="ilastname"
										>
											{t("login:lastname.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="ilastname"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="idisplayname"
										>
											{t("login:displayname.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="idisplayname"
											required
										/>
									</td>
								</tr>
								<tr>
									<td colSpan="3">
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="ibirthDate"
										>
											{t("login:birth-date.label")}*
										</label>
										<input
											className="form-control"
											type="date"
											id="ibirthDate"
											required
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table table-bordered w-100 no-border">
							<thead className="table-danger">
								<tr style={{ height: "10px" }}>
									<td></td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<img
											className="img-md-responsive"
											src={require("../../../rits-package/img/icons/small/noImg.png")}
											id="iimageImg"
										/>
										<div>
											<input
												className="form-control"
												type="file"
												id="iimage"
												onChange={() => {
													// Get file from input iimage
													var file = document.getElementById("iimage").files[0];
													// Convert file to b64
													var reader = new FileReader();
													var b64 = "";
													reader.onloadend = function () {
														document.getElementById("iimageImg").src =
															reader.result;

														b64 = reader.result;
														// remove data:image/webp;base64, from b64
														b64 = b64.replace(
															/^data:image\/[a-z]+;base64,/,
															""
														);
														document.getElementById("iimageTxt").value = b64;
													};
													reader.readAsDataURL(file);
												}}
											/>
											<input type="text" id="iimageTxt" hidden="hidden" />
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table table-bordered w-100 no-border">
							<tbody>
								<tr>
									<td colSpan="2">
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iphone"
										>
											{t("login:phone-number.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="iphone"
											maxLength={12}
											minLength={10}
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iaddressName"
										>
											{t("login:address-name.label")}*
										</label>
										<select
											className="form-control  js-single"
											id="iaddressName"
										>
											{atOptions}
										</select>
									</td>
								</tr>
								<tr>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iaddress1"
										>
											{t("login:address-1.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="iaddress1"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iaddress2"
										>
											{t("login:address-2.label")}
										</label>
										<input
											className="form-control"
											type="text"
											id="iaddress2"
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="iaddress3"
										>
											{t("login:address-3.label")}
										</label>
										<input
											className="form-control"
											type="text"
											id="iaddress3"
										/>
									</td>
								</tr>
								<tr>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="izipcode"
										>
											{t("login:zip-code.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="izipcode"
											maxLength={5}
											minLength={5}
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="icity"
										>
											{t("login:city.label")}*
										</label>
										<input
											className="form-control"
											type="text"
											id="icity"
											required
										/>
									</td>
									<td>
										<label
											className="form-label float-start badge txt-dark trn"
											htmlFor="icountry"
										>
											{t("login:country.label")}*
										</label>
										<select
											className="form-control  js-single"
											id="icountry"
											required
										>
											{cOptions}
										</select>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table table-bordered w-100 no-border">
							<tbody>
								<tr>
									<td colSpan="3">
										<center>
											<button
												className="btn btn-info"
												onClick={submitAccount}
												type="button"
												id="submit-account"
											>
												<i className="fa fas fa-user-plus"></i>&nbsp;
												<span className="m-2 mb-0 mt-0">
													{" "}
													{t("login:submit-account.label")}
												</span>
											</button>
										</center>
									</td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>
		);
	}
	/**
	 * Returns the component
	 */
	return !loaded ? null : (
		<>
			<input type="hidden" id={componentId} />
			{setAccountForm()}
		</>
	);
};

CreateAccount.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	submitFunction: PropTypes.func
};

export default CreateAccount;
