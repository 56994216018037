const lsName="paymentState";
function checkPayment(cpid) {
	var paymentStep = getPaymentStep(cpid);
	return paymentStep;
}
function createPaymentStep(cpid) {
	var item = {
		cpid: cpid,
		context: process.env.REACT_APP_NAME
	};
	addPaymentStep(item);
}
function removePaymentStep(cpid) {
	var paymentState = getPaymentSteps();
	if (paymentState != null || paymentState.length > 0) {
		// Check if any paymentState with context = process.env.REACT_APP_NAME && cpid = cpid
		var item = findItem(paymentState, cpid);

		if (item != null) {
			paymentState.splice(paymentState.indexOf(item), 1);
		}
	}
	// save paymentState to localStorage
	localStorage.setItem(lsName, JSON.stringify(paymentState));
}
function addPaymentStep(payItem) {
	// get paymentState from localStorage
	var paymentState = getPaymentSteps();
	// push payItem to paymentState
	if (paymentState.length > 0) {
		var item = findItem(paymentState, payItem.cpid);
		if (item != null) {
			paymentState.splice(paymentState.indexOf(item), 1);
		}
	}
	paymentState.push(payItem);
	// save paymentState to localStorage
	localStorage.setItem(lsName, JSON.stringify(paymentState));
}

function findItem(list, cpid) {
	var item = null;
	try {
		item = list.find(
			(x) => x.cpid === cpid && x.context === process.env.REACT_APP_NAME
		);
	} catch {
		// remove guests
		localStorage.removeItem(lsName);
		getPaymentSteps();
	}
	return item;
}

function getPaymentSteps() {
	var paymentState = localStorage.getItem(lsName);
	if (paymentState == null) {
		paymentState = [];
	} else {
		paymentState = JSON.parse(paymentState);
	}

	return paymentState;
}

function getPaymentStep(cpid) {
	var item = null;
	var paymentState = getPaymentSteps();
	if (paymentState != null) {
		// Check if any paymentState with context = process.env.REACT_APP_NAME && cpid = cpid
		item = findItem(paymentState, cpid);
	}
	return item;
}

module.exports = {
	checkPayment,
	createPaymentStep,
	removePaymentStep
};
