/**
 * ! Change CustomFooter by your component name
 */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import CookieBanner from "../specific/cookie-consent.js";
import DocumentModal from "../../common/shared/document-modal.js";

const CustomFooter = (_props) => {
	var props = _props.properties;
	const componentId = "CustomFooter-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	const [cookieBan, setCookieBan] = useState(null);
	const effectRan = useRef(false);

	useEffect(() => {
		if (!effectRan.current) {
			props = _props.properties;
			// Reload data on loaded state change.
			componentDidMount();
			setCookieBan(
				<CookieBanner
					properties={{ visibility: "byCookieValue" }}
				></CookieBanner>
			);
		}
		return () => {
			effectRan.current = true;
		};
	}, [cookieBan]);

	function componentDidMount() {
		try {
			//Loaded
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.contains("localhost")) { onError(e); }
		}
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: props.trNamespace }), `Custom footer ${error}`);
	}
	return (
		<>
			<footer
				className="footer bg-primary txt-primary footer-shadow footer-bottom footer-back-zindex"
				key={componentId}
			>
				<div className="container-inline-spaced">
					<a className="nav-link active m-2" href="https://menu.app-o2.com" target="_blank" rel="noreferrer">
						<img
							className="img-xs"
							src={require("../../../rits-package/img/logos/AO2-dark.png")}
						/>
						<span>{" "}</span>
						<span>{"©️"}{" "}</span>
						<span>{new Date().getFullYear()}</span>
						<span>{" "}</span>
						<span className="trn ">{t("index:Company.brand")}</span>

					</a>
					<ul className="navbar-nav m-4 mt-0 mb-2">
						<li className="nav-item dropdown ">
							<a
								className="nav-link active trn dropdown-toggle"
								href="#"
								id="navbarDropdown"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
								onClick={() => {
									var el = document.getElementById("navbarFooterDropdown");
									if (el.classList.contains("show")) {
										el.classList.remove("show");
									} else {
										el.classList.add("show");
										el.style = "";
										el.style = "position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate(0px, -32px);"
									}
								}}
							>
								<span className="trn">{t("index:Legal.title")}</span>
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarFooterDropdown" id="navbarFooterDropdown">
								<li className="nav-link active animate blur">
									<a
										className="dropdown-item  trn"
										data-bs-toggle="modal"
										data-bs-target="#privacy-modal"
									>
										{t("index:Privacy.title")}
									</a>
								</li>
								<li className="nav-link active animate blur">
									<a
										className="dropdown-item  trn"
										data-bs-toggle="modal"
										data-bs-target="#cgv-modal"
									>
										{t("index:Cgv.title")}
									</a>
								</li>
								<li className="nav-link active animate blur">
									<a
										className="dropdown-item  trn"
										data-bs-toggle="modal"
										data-bs-target="#cgu-modal"
									>
										{t("index:Cgu.title")}
									</a>
								</li>
								<li className="nav-link active animate blur">
									{" "}
									<a
										className="dropdown-item  trn"
										data-id="cookie-consent"
										onClick={() => {
											document.cookie =
												"menu-app-o2-rgpd=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
											effectRan.current = false;
											setCookieBan(
												<CookieBanner
													properties={{ visibility: "show" }}
												></CookieBanner>
											);
										}}
									>
										{t("index:Cookie.title")}
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</footer>
			<div id="cookie-ban-main">{cookieBan}</div>
			<DocumentModal
				properties={{
					modalId: "privacy-modal",
					documentUrl: require('../../pdf/privacy.pdf'),
					documentName: "App-O²-privacy.pdf",
					modalTitleKey: "cookies.more-info.privacy-link",
					trNamespace: "modals"
				}}
			></DocumentModal>
			<DocumentModal
				properties={{
					modalId: "cgu-modal",
					documentUrl: require('../../pdf/cgu.pdf'),
					documentName: "App-O²-cgu.pdf",
					modalTitleKey: "cookies.more-info.privacy-link",
					trNamespace: "modals"
				}}
			></DocumentModal>
			<DocumentModal
				properties={{
					modalId: "cgv-modal",
					documentUrl: require('../../pdf/cgv-client.pdf'),
					documentName: "App-O²-cgv-client.pdf",
					modalTitleKey: "cookies.more-info.privacy-link",
					trNamespace: "modals"
				}}
			></DocumentModal>
		</>
	);
};

CustomFooter.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	isFixed: PropTypes.bool
};

export default CustomFooter;
