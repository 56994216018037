import sso from "./SSO/claim-access.js";
import productsService from "./application/product.js";
import companyDatas from "./application/company.js";
import categoryDatas from "./datas/category-datas.js";
import order from "./application/order.js";
import companyImages from "./datas/company-images.js";

var isWorking = false;

export async function LoadData() {
	//if (!isWorking) {
	isWorking = true;
	var name = null;
	await sso.loginWithLocalDb();
	var data = null;
	var company = await LoadCompanyData();
	var products = await LoadProductData();
	var categories = CheckCategoriesWithProducts(
		await LoadCategoryData(),
		products
	);
	var orders = null;
	name = global.LOGGED_USER?.Email ?? "";
	var companyDetails = null;
	var companyName = "";
	if (company.companyDetails != null && company.companyDetails.length > 0) {
		companyDetails = company.companyDetails[0];
		companyName = companyDetails.CompanyName;
	}
	data = {
		name,
		companyName,
		companyDetails,
		company,
		categories,
		products,
		orders
	};
	isWorking = false;
	return data;
	//}
}
export async function LoadDetailsData(forceOnline = false) {
	if (!isWorking) {
		isWorking = true;
		var name = null;
		global.LOGGED_USER = {
			Email: "",
			Password: ""
		};
		var data = null;
		var company = null;
		if (!forceOnline) company = await LoadCompanyDataLocal();
		if (company == null) {
			company = await LoadCompanyData();
		}
		name = global.LOGGED_USER?.Email;
		var companyDetails = null;
		if (company.companyDetails != null && company.companyDetails.length > 0) {
			companyDetails = company.companyDetails[0];
			setGlobalVars(companyDetails);
		}
		var companyName = companyDetails.CompanyName;
		data = {
			name,
			companyName,
			companyDetails,
			company
		};
		data = JSON.parse(JSON.stringify(data));
		isWorking = false;
		return data;
	}
}
export async function LoadCategoryData() {
	var item = await categoryDatas.GetFilter();
	// filter to get only IsActive = true
	item = item.filter((x) => x.IsActive == true);
	// sort by PositionCategory then by CategoryName
	item.sort((a, b) => a.PositionCategory - b.PositionCategory);

	return item;
}
export async function LoadProductData() {
	var item = await productsService.GetFilter();
	// filter to get only IsActive = true
	item = item.filter((x) => x.IsActive == true);
	return item;
}
export async function LoadCompanyImages() {
	isWorking = true;
	await sso.loginWithLocalDb();
	var data = await companyImages.GetFilter();
	return data;
}
export async function LoadCompanyDataLocal() {
	var item = await companyDatas.GetOneLocal();
	if (
		item != null &&
		item.companyDetails != null &&
		item.companyDetails.length > 0
	)
		setGlobalVars(item.companyDetails[0]);
	return item;
}
export async function LoadCompanyData() {
	var item = await companyDatas.GetOne();
	return item[0];
}

export async function LoadAllCompanyDatas() {
	var items = await companyDatas.GetAll();
	return items;
}
export async function LoadOrders(cpid) {
	try {
		var items = await order.GetAll();
		// filter items with companyId = cpid
		items = items.filter((x) => x.CompanyId == cpid);
	} catch (err) {
		items = [];
	}
	return items;
}
export function CheckCategoriesWithProducts(categories, products) {
	// Check if all categories have at least one product products
	var categoriesWithProducts = [];
	products = products.filter((product) => product.IsActive == true);
	var prodList = [];
	products.forEach((product) => {
		if (product.ProductOptions != null && product.ProductOptions.length > 0) {
			if (product.ProductOptions[0].IsVisible) {
				prodList.push(product);
			}
		}
	});
	categories.forEach((category) => {
		var categoryProducts = prodList.filter(
			(product) => product.CategoryId == category.Id
		);
		if (categoryProducts.length > 0) {
			categoriesWithProducts.push(category);
		}
	});
	return categoriesWithProducts;
}
export function LoadProductsFromCategory(categoryId, products) {
	var categoryProducts = products.filter(
		(product) => product.CategoryId == categoryId && product.IsActive == true
			&& (product.ProductOptions != null && product.ProductOptions.length > 0 && product.ProductOptions[0].IsVisible)
	);
	return categoryProducts;
}
export async function loadUser(email, password) {
	var user = await sso.postClaimAccess(email, password);
	if (user) {
		global.LOGGED_USER = {
			Email: email,
			Password: password
		};
	}
	return user;
}
export async function logingWithLocalDb() {
	var res = await sso.loginWithLocalDb();
	return res;
}
export async function logout() {
	return await sso.Logout();
}
function setGlobalVars(item) {
	global.cpname = item.CompanyName;
	global.cplogo = item.CompanyLogo;
	global.TOKEN = null;
	if (process.env.REACT_APP_COMPANY_ID != null) {
		global.cpname = process.env.REACT_APP_SITE_TITLE;
		global.cplogo = process.env.REACT_APP_CIRCLE_LOGO;
	}
}
