/**
 * ! Change PaymentForm by your component name
 */

import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import userDetailsService from "../../../data/services/application/user-details.js";
import CheckoutForm from "./checkout-form.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import order from "../../../data/services/application/order.js";
import CustomSpinner from "../shared/custom-spinner-small.js";

const _basketModel = require("../../models/basket.js");
const _companyModel = require("../../models/company.js");
const _guestModel = require("../../models/guest.js");
const _deliveryModel = require("../../models/delivery.js");
const _paymentModel = require("../../models/payment.js");
/**
 * Payment form component
 * @param {*} _props
 * @returns
 */
const PaymentForm = (_props) => {
	//! Properties
	var props = _props.properties;
	const componentId = "PaymentForm-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState(null);
	const [retry, setRetry] = useState(0);

	const appearance = {
		theme: "stripe"
	};
	const options = {
		clientSecret,
		appearance
	};
	var _cpLocal = _companyModel.getCompanyDatas();
	//! Init
	useEffect(() => {
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			if (loaded) return;
			if (props.image != null) {
				// add b64 source before b64 image content
				if (!props.image.includes("data:image/png;base64,")) {
					props.image = "data:image/png;base64," + props.image;
				}
			} else {
				props.image = require("../../../rits-package/img/logos/AO2-dark.png");
			}
			await setUserData();
			setLoadedState(true);

			setTimeout(async () => {
				await setStripe();
				await setFormFields();
			}, 500);
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	/**
	 * Sets the user data
	 * @returns
	 */
	async function setUserData() {
		try {
			var _usr = {
				Id: null,
				FirstName: "",
				LastName: "",
				Email: "",
				PhoneNumber: ""
			};
			if (global.LOGGED_USER != null) {
				var loggedUser = await userDetailsService.GetAll();
				if (loggedUser != null) {
					_usr = {
						Id: loggedUser[0].UserId,
						FirstName: loggedUser[0].FirstName,
						LastName: loggedUser[0].LastName,
						Email: global.LOGGED_USER.Email,
						PhoneNumber: loggedUser[0].PhoneNumber
					};
				}
			} else {
				var guest = checkGuest();
				if (guest) {
					// set user data with guest data
					_usr = {
						Id: null,
						FirstName: guest.firstName,
						LastName: guest.lastName,
						Email: guest.email,
						PhoneNumber: guest.phoneNumber
					};
				}
			}
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}

		return _usr;
	}
	//! Functions
	/**
	 * Checks if the user is a guest
	 * @returns
	 */
	function checkGuest() {
		return _guestModel.checkGuest(_cpLocal.companyId);
	}
	/**
	 * Sets the order
	 * @returns
	 */
	async function setOrder() {
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		var delivery = _deliveryModel.getDelivery(_cpLocal.companyId);
		// set date with delivery date and delivery time in user timezone
		var deliveryDate = new Date();
		if (delivery != null) {
			deliveryDate = new Date(delivery.selectedDate);
			deliveryDate.setHours(
				delivery.selectedTime.split(":")[0],
				delivery.selectedTime.split(":")[1],
				0,
				0
			);
			// Get timezone offset of browser
			var offset = new Date().getTimezoneOffset() * -1;
			// Add offset to deliveryDate
			deliveryDate.setMinutes(deliveryDate.getMinutes() + offset);
		}
		var _usr = await setUserData();
		var orderObject = {
			companyId: _cpLocal.companyId,
			userId: _usr.Id ?? null,
			firstName: _usr.FirstName,
			lastName: _usr.LastName,
			email: _usr.Email,
			phone: _usr.PhoneNumber,
			basket: basket,
			deliveryDate: deliveryDate,
			orderComment: delivery.comment
		};
		return orderObject;
	}
	/**
	 * Sets the stripe
	 */
	async function setStripe() {
		try {
			var _order = await order.GetClientSecret(_cpLocal.companyId);
			setStripePromise(loadStripe(_order.publicKey));
			var orderObject = await setOrder();
			var _clientSecret = await order.GetPaymentForm(orderObject);
			if (_clientSecret != null) {
				setClientSecret(_clientSecret.clientSecret);
			} else {
				setTimeout(async () => {
					setRetry(retry + 1);
					if (retry > 5) {
						sendToast(
							`payment ${t("common:error-occured")}`,
							"error",
							t("error", { ns: props.trNamespace })
						);
					} else {
						await setStripe();
					}
				}, 150);
			}
		} catch {
			// do nothing here
			setRetry(retry + 1);
			if (retry > 2) {
				sendToast(
					`payment ${t("common:error-occured")}`,
					"error",
					t("error", { ns: props.trNamespace })
				);
			}
		}
	}
	/**
	 * Sets the form fields
	 * @returns
	 */
	async function setFormFields() {
		try {
			if (
				document.getElementById("pay_firstName").value != null &&
				document.getElementById("pay_firstName").value.length > 0
			)
				return;
			else {
				var _usr = await setUserData();
				if (_usr != null) {
					document.getElementById("pay_firstName").value = _usr?.FirstName;
					document.getElementById("pay_lastName").value = _usr.LastName;
					document.getElementById("pay_email").value = _usr.Email;
					document.getElementById("pay_phone").value = _usr.PhoneNumber;
				}
			}
		} catch {
			setTimeout(async () => {
				await setFormFields();
			}, 250);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`payment ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	/**
	 * Sets the pay card
	 * @returns
	 */
	function setPayCard() {
		// return recap card with first name, last name, email, phone and total price
		var delivery = _deliveryModel.getDelivery(_cpLocal.companyId);
		var date = delivery.selectedDate;
		var time = delivery.selectedTime;
		// Add pay button
		return (
			<div className="card">
				<div className="card-header bg-info">
					<h3 className="card-title">
						<div className="container-inline-centered">
							<span className="m-2 mb-0 mt-0">{t("orders:pay")}</span>
						</div>
					</h3>
				</div>
				<div className="card-body bg-light" id="pay-form-body">
					<form>
						<div className="row">
							<div className="col-6">
								<label
									htmlFor="pay_firstName"
									className="form-label float-start badge txt-dark"
								>
									{t("login:firstname.label")}
								</label>
								<input
									type="text"
									className="form-control"
									id="pay_firstName"
									disabled
								/>
							</div>
							<div className="col-6">
								<label
									htmlFor="pay_lastName"
									className="form-label float-start badge txt-dark"
								>
									{t("login:lastname.label")}
								</label>
								<input
									type="text"
									className="form-control"
									id="pay_lastName"
									disabled
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<label
									htmlFor="pay_email"
									className="form-label float-start badge txt-dark"
								>
									{t("login:email.label")}
								</label>
								<input
									type="email"
									className="form-control"
									id="pay_email"
									disabled
								/>
							</div>
							<div className="col-6 ">
								<label
									htmlFor="pay_phone"
									className="form-label float-start badge txt-dark"
								>
									{t("login:phone-number.label")}
								</label>
								<input
									type="tel"
									className="form-control"
									id="pay_phone"
									disabled
								/>
							</div>
						</div>
						<hr></hr>
						<div className="row">
							<div className="col-6">
								<label
									htmlFor="pay_phone"
									className="form-label float-start badge txt-dark"
								>
									{t("orders:for")}
								</label>
								<input
									type="date"
									className="form-control"
									value={date}
									disabled
								/>
							</div>
							<div className="col-6">
								<label
									htmlFor="pay_phone"
									className="form-label float-start badge txt-dark"
								>
									{t("orders:at")}
								</label>
								<input
									type="time"
									className="form-control"
									value={time}
									disabled
								/>
							</div>
						</div>
					</form>
					<hr />
					<div className="mt-2">
						{!clientSecret && (
							<div
								className={`col-xs-12 col-md-6 col-lg-4 w-100 col-xxl-3 p-2 animate spin `}
								style={{ height: "300px" }}
								key={componentId}
								id={componentId}
							>
								<div className=" card h-100 w-100 app-lighter-bg-color">
									<CustomSpinner
										properties={{
											addClass: "spin-border-l"
										}}
									></CustomSpinner>
									<h4 className="text-primary">{t("orders:check-cart")}</h4>
								</div>
							</div>
						)}
						{clientSecret && (
							<Elements options={options} stripe={stripePromise}>
								<CheckoutForm
									properties={{
										submitCallback: paymentSuccessCallback
									}}
								></CheckoutForm>
							</Elements>
						)}
					</div>
				</div>
			</div>
		);
	}
	/**
	 * Payment success callback
	 * @param {*} data
	 */
	async function paymentSuccessCallback(data) {
		// Disable all steps buttons data-type="step-btn"
		var stepBtns = document.querySelectorAll('[data-type="step-btn"]');
		stepBtns.forEach((btn) => {
			btn.disabled = true;
		});
		var paymentIntentId = data.id ?? "";
		var orderObject = await setOrder();
		orderObject.paymentIntentId = paymentIntentId;
		var confirm = await order.GetPaymentConfirm(orderObject);
		var content = null;
		if (confirm != null && confirm.status == "succeeded") {
			var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
			var delivery = _deliveryModel.getDelivery(_cpLocal.companyId);
			// Set recap cart with day and hour of delivery
			var deliveryCard = null;
			if (delivery != null) {
				deliveryCard = (
					// Same but not in table
					<div className="container-inline-centered">
						<div className="">
							<span className="badge font-bolder txt-dark">
								{t("orders:delivery-date")}
							</span>
							<br />
							<span className="badge txt-dark">{delivery.selectedDate}</span>
						</div>
						<div className="">
							<span className="badge font-bolder txt-dark">
								{t("orders:delivery-hour")}
							</span>
							<br />
							<span className="badge txt-dark">{delivery.selectedTime}</span>
						</div>
					</div>
				);
			}
			// Set recap of all products in basket with Product, VariantName, Qty
			var productCard = null;
			if (basket != null) {
				var totalPrice = 0;
				var totalQty = 0;
				basket.products.forEach((product) => {
					totalPrice +=
						parseFloat(product.TotalUnitPrice) * parseInt(product.TotalQty);
					totalQty += product.TotalQty;
				});
				productCard = (
					<div className="card">
						<div className="card-header bg-primary">
							<div className="row">
								<div className="col-6">
									<span
										className="badge font-bolder"
										style={{ whiteSpace: "wrap" }}
									>
										{t("orders:product")}
									</span>
								</div>
								<div className="col-3">
									<span
										className="badge font-bolder"
										style={{ whiteSpace: "wrap" }}
									>
										{t("orders:qty")}
									</span>
								</div>
								<div className="col-3">
									<span
										className="badge font-bolder"
										style={{ whiteSpace: "wrap" }}
									>
										{t("orders:price")}
									</span>
								</div>
							</div>
						</div>
						<div className="card-body">
							{basket.products.map((product, index) => {
								return (
									<div className="row" key={index}>
										<div className="col-6">
											<span
												className="badge txt-dark"
												style={{ whiteSpace: "wrap" }}
											>
												{product.Product}{" "}
												{product.Variant.variantName == t("orders:price")
													? ""
													: product.Variant.variantName}
											</span>
										</div>
										<div className="col-3">
											<span
												className="badge txt-dark"
												style={{ whiteSpace: "wrap" }}
											>
												x {product.TotalQty}
											</span>
										</div>
										<div className="col-3">
											<span
												className="badge txt-dark"
												style={{ whiteSpace: "wrap" }}
												data-bs-toggle="tooltip"
												data-bs-title={`t("orders:qty"): ${totalQty}`}
											>
												{product.TotalPrice}
											</span>
										</div>
									</div>
								);
							})}
							<hr></hr>
							<div className="row">
								<div className="col-9">
									<span
										className="badge font-bolder txt-dark"
										style={{ whiteSpace: "wrap" }}
									>
										{t("orders:total-price")}
									</span>
								</div>
								<div className="col-3">
									<span
										className="badge font-bolder txt-dark"
										style={{ whiteSpace: "wrap" }}
									>
										{totalPrice.toFixed(2)} {basket.currency}
									</span>
								</div>
							</div>
						</div>
					</div>
				);
			}
			sendToast(t("orders:payment-success"), "success", t("common:success"));
			content = (
				<div className="card-body">
					<div className="container-inline-centered">
						<i className="fas fa-check-circle fa-2x text-success"></i>
						<h2 className="text-success m-4 mb-0 mt-0">
							{t("orders:payment-success")}
						</h2>
					</div>
					<div className="m-4"></div>
					{deliveryCard}
					{productCard}
				</div>
			);
			// get pay-form-body and set inner html with check and payment successful text
			document.getElementById("pay-form-body").innerHTML =
				renderToString(content);
			// Delete payementState
			_paymentModel.removePaymentStep(_cpLocal.companyId);
			// Delete basket
			_basketModel.deleteBasket(_cpLocal.companyId);
			// Delete delivery
			_deliveryModel.deleteDelivery(_cpLocal.companyId);
		} else {
			sendToast(t("orders:payment-error"), "error", t("common:error"));
			content = (
				<div className="container-inline-centered">
					<i className="fas fa-times-circle fa-2x text-danger"></i>
					<h2 className="text-danger m-4 mb-0 mt-0">
						{t("orders:payment-error")}
					</h2>
				</div>
			);
			// get pay-form-body and set inner html with cross and payment error
			document.getElementById("pay-form-body").innerHTML =
				renderToString(content);
		}
		window.scrollTo(0, 0);
	}
	/**
	 * Render
	 */
	return loaded ? (
		<>
			<input type="hidden" id={componentId} />
			{setPayCard()}
		</>
	) : null;
};

PaymentForm.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	isGuest: PropTypes.bool,
	image: PropTypes.string
};

export default PaymentForm;
