/**
 * ! Change UserOrders by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { LoadOrders } from "../../../data/services/load-data-service.js";
import { setTheme, getTheme } from "../../models/themes.js";
import CustomSpinner from "../../common/shared/custom-spinner.js";
const _companyModel = require("../../models/company.js");

const UserOrders = (_props) => {
	var props = _props.properties;
	const componentId = "UserOrders-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const [orders, setOrders] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	setTheme(getTheme());
	var _cpLocal = _companyModel.getCompanyDatas();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			setLoadFunction();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.contains("localhost")) { onError(e); }
		}
	}
	// Execute after load until all is ok.
	async function setLoadFunction() {
		try {
			var data = await LoadOrders(_cpLocal.companyId);
			setOrders(data);

			if (data != null) {
				setTimeout(() => {
					setLoadedState(true);
				}, 500);
			}
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}

	function setOrdersElements(OrderStatus) {
		// Get orders with status = 1
		// Set accordion, and show orders as card
		var ordersCard = null;
		var orderColor = "bg-primary";
		if (OrderStatus == 1) {
			orderColor = "bg-warning";
		}
		if (OrderStatus == 2) {
			orderColor = "bg-info";
		}
		if (OrderStatus == 3) {
			orderColor = "bg-success";
		}
		var __orders = orders.filter((order) => order.OrderStatus == OrderStatus);
		if (__orders != null && __orders.length > 0) {
			__orders = __orders.sort((a, b) => {
				return new Date(b.DeliveryDate) - new Date(a.DeliveryDate);
			});
			ordersCard = __orders.map((order, index) => {
				//foreach order.OrderDeatils set content card with item.ProductData.Product item.TotalQty item.TotalPrice
				var products = order.OrderDetails.map((item, index) => {
					// Get item.RemovedIngredients and foreach add t("common:without")+" "+
					return (
						<div
							className="card-body"
							key={`p-${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`}
						>
							<h5 className="card-title">{item.ProductData.Product}</h5>
							{item.RemovedIngredientsList != null &&
								item.RemovedIngredientsList.length > 0 ? (
								<p>
									{item.RemovedIngredientsList.map((ing, index) => {
										return (
											<span
												key={`${ing.IngredientName}-${ing.Id}-${index}`}
												className="badge bg-danger m-1 mb-0 mt-0"
											>
												{t("common:without")}&nbsp;
												{ing.IngredientName}
											</span>
										);
									})}
								</p>
							) : null}
							{item.AddedIngredientsList != null &&
								item.AddedIngredientsList.length > 0 ? (
								<p>
									{item.AddedIngredientsList.map((ing, index) => {
										return (
											<span
												key={`${ing.IngredientName}-${ing.Id}-${index}`}
												className="badge bg-success m-1 mb-0 mt-0"
											>
												{ing.IngredientName}
											</span>
										);
									})}
								</p>
							) : null}
							{item.AdditionalProductsList != null &&
								item.AdditionalProductsList.length > 0 ? (
								<p>
									{item.AdditionalProductsList.map((prod, index) => {
										return (
											<span
												key={`${prod.Product}-${prod.Id}-${index}`}
												className="badge bg-primary m-1 mb-0 mt-0"
											>
												&nbsp;{prod.Product}
											</span>
										);
									})}
								</p>
							) : null}
							<p className="card-text">
								{t("orders:qty")} &nbsp;
								{item.TotalQty} &nbsp;
								{t("orders:price")} &nbsp;
								{item.TotalPrice}
							</p>

							{index == order.OrderDetails.length - 1 ? null : <hr></hr>}
						</div>
					);
				});
				var footer = (
					<div className={`card-header  bg-primary-gradient`}>
						<h5>
							<span className={`badge ${orderColor}`}>
								{t("orders:total-price")} {order.TotalIncludingTax}{" "}
								{_cpLocal.currency}
							</span>
						</h5>
					</div>
				);

				// Card.
				var dtD = new Date(order.DeliveryDate).toLocaleDateString();
				var dtH = `${new Date(order.DeliveryDate).toISOString().split("T")[1].split(":")[0]}:${new Date(order.DeliveryDate).toISOString().split("T")[1].split(":")[1]}`;
				return (
					<div
						className="col-sm-12 col-md-6 col-lg-4"
						key={`accordionOrders-${order.Id}-${index}`}
					>
						<div className="card">
							<div className={`card-header bg-primary-gradient`}>
								<h4 className="m-2">
									<span className={`badge ${orderColor}`}>
										{t("orders:order-summary")}
									</span>
								</h4>
								<h5 className="m-2 ">
									{dtD} {t("details:at")} {dtH}
								</h5>
							</div>
							<div className="card-body">
								<div
									className="accordion"
									id={`accordionOrders-${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`}
									key={`${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`}
								>
									<div className="accordion-item">
										<h2 className="accordion-header">
											<button
												className={`accordion-button bg-light`}
												type="button"
												onClick={() => {
													// Get element by id navbarSupportedContent
													var el = document.getElementById(
														`collapseOrder-${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`
													);
													// if el has class show remove
													if (el.classList.contains("show")) {
														el.classList.remove("show");
													} else {
														el.classList.add("show");
													}
												}}
											>
												{t(`orders:products`)}
											</button>
										</h2>
										<div
											id={`collapseOrder-${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`}
											className="accordion-collapse collapse"
											data-bs-parent={`#accordionOrders-${order.BasketId}-${__orders.Id}-${OrderStatus}-${index}`}
										>
											<div className="accordion-body">{products}</div>
										</div>
									</div>
								</div>
							</div>
							{footer}
						</div>
					</div>
				);
			});

			return (
				<div className="accordion" id={`accordionOrders--${OrderStatus}`}>
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button
								className={`accordion-button bg-secondary-gradient`}
								type="button"
								onClick={() => {
									// Get element by id navbarSupportedContent
									var el = document.getElementById(
										`collapseOne-${OrderStatus}`
									);
									// if el has class show remove
									if (el.classList.contains("show")) {
										el.classList.remove("show");
									} else {
										el.classList.add("show");
									}
								}}
							>
								<h5>
									<span className={`badge ${orderColor}`}>
										{t(`orders:order-status-${OrderStatus}`)}
									</span>
								</h5>
							</button>
						</h2>
						<div
							id={`collapseOne-${OrderStatus}`}
							className="accordion-collapse collapse"
							data-bs-parent="#accordionExample"
						>
							<div className="accordion-body">
								<div className="row">{ordersCard} </div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	return loaded ? (
		<div className="content-container content-margin-top" key={componentId}>
			<input type="hidden" id={componentId} />
			{setOrdersElements(3)}
			{setOrdersElements(2)}
			{setOrdersElements(1)}
			{setOrdersElements(4)}
			{setOrdersElements(5)}
		</div>
	) : (<div className="content-container content-margin-top">
		<header className="App-header">
			<CustomSpinner
				properties={{
					pageName: props.pageName,
					trNamespace: props.trNamespace
				}}
			/>
		</header>
	</div>);
};

UserOrders.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string
};

export default UserOrders;
