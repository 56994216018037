/**
 * ! Change LoginForm by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import { loadUser } from "../../../data/services/load-data-service.js";
import ReactRecaptcha3 from "react-google-recaptcha3";
import loginService from "../../../data/services/application/login.js";

const _userModel = require("../../models/user.js");
const CaptchaKey = "6Ldv6gwqAAAAAEmk2Jq9VFGcdyIotlZITBGCVheH";

/**
 * Login form component 
 * @param {*} _props 
 * @returns 
 */
const LoginForm = (_props) => {
	//! Properties
	var props = _props.properties;
	const componentId = "LoginForm-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	//! Init
	useEffect(() => {
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount 
	 */
	function componentDidMount() {
		try {
			ReactRecaptcha3.init(CaptchaKey).then(() => {
				setLoadedState(true);
			});
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(e);}
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`Login Form ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message. 
	 * @param {*} message 
	 * @param {*} severity 
	 * @param {*} title 
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	//! Functions
	/**
	 * Submits the login form 
	 * @returns 
	 */
	function submitLogin() {
		var form = document.getElementById("login-form");
		if (form.checkValidity() === false) {
			form.classList.add("was-validated");
			return;
		}
		// remove button with id forgot-password disable button login-btn and add spinner
		// if button with id forgot-password exists
		if (document.getElementById("forgot-password")) {
			document.getElementById("forgot-password").remove();
			document.getElementById("login-btn").disabled = true;
		}
		document.getElementById(
			"login-btn"
		).innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`;
		// serialize form
		var formData = {};
		var elements = form.querySelectorAll("input, select");
		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];
			formData[element.id] = element.value;
		}
		ReactRecaptcha3.getToken().then(
			(token) => {
				formData.token = token;
				// TODO: POST Create account here!
				// Get email and password
				var email = document.getElementById("username").value;
				var password = document.getElementById("password").value;
				if (email === "" || password === "") {
					sendToast("Please enter your email and password", "error", "Login");
					// enable button login-btn and set back content
					document.getElementById("login-btn").disabled = false;
					document.getElementById(
						"login-btn"
					).innerHTML = `<i className="fas fa-user-unlock"></i>&nbsp; <span className="m-2 mb-0 mt-0">${t(
						"login",
						{ ns: props.trNamespace }
					)}</span>`;
					return;
				}
				loadUser(email, password).then((data) => {
					if (data == true) {
						_userModel.getSetUser(global.LOGGED_USER);

						props.submitFunction();
					} else {
						sendToast(t("login:errors.incorrect"), "error", t("common:error"));
						// if button with id forgot-password does not exist
						if (!document.getElementById("forgot-password")) {
							// append button behind login with forgot password
							var button = document.createElement("button");
							button.setAttribute("type", "button");
							// set button centered
							button.setAttribute("class", "btn btn-danger m-2 mb-0 mt-0");
							button.setAttribute("id", "forgot-password");
							button.innerHTML = `<i className="fas fa-key"></i>&nbsp;
							<span className="m-2 mb-0 mt-0">
								${t("forgot-password", { ns: props.trNamespace })}
							</span>`;
							form.appendChild(button);
							// add event listener to button
							document
								.getElementById("forgot-password")
								.addEventListener("click", forgotPassword);
						}
						// enable button login-btn and set back content
						document.getElementById("login-btn").disabled = false;
						document.getElementById(
							"login-btn"
						).innerHTML = `<i className="fas fa-user-unlock"></i>&nbsp; <span className="m-2 mb-0 mt-0">${t(
							"login",
							{ ns: props.trNamespace }
						)}</span>`;
					}
				});
			},
			(error) => {
				if (process.env.REACT_APP_APIS_URL.includes("localhost")) {onError(error);}
			}
		);
	}
	/**
	 * Sets the login card 
	 * @returns 
	 */
	function setCard() {
		return (
			<div className="card m-4">
				<div className="card-header bg-primary-gradient">
					<div className="container-inline-centered">
						<img
							src={require("../../../rits-package/img/logos/AO2-dark.png")}
							alt="AO2"
							className="img-sm"
						/>
						<h1 className="">
							{t(props.pageName, {
								ns: props.trNamespace
							})}
						</h1>
					</div>
				</div>
				<div className="card-body bg-light">
					<form className="mb-3 needs-validation" noValidate id="login-form">
						<div className="form-group mb-3">
							<label htmlFor="username" className="float-start badge txt-dark">
								{t("email.label", { ns: props.trNamespace })}
							</label>
							<input
								type="text"
								className="form-control"
								id="username"
								placeholder={t("email.label", { ns: props.trNamespace })}
								autoComplete="username"
								required
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="password" className="float-start badge txt-dark">
								{t("password.label", { ns: props.trNamespace })}
							</label>
							<input
								type="password"
								className="form-control"
								id="password"
								placeholder={t("password.label", { ns: props.trNamespace })}
								autoComplete="current-password"
								required
							/>
						</div>
						<button
							type="button"
							className="btn btn-info m-auto"
							id="login-btn"
							onClick={() => {
								submitLogin();
							}}
						>
							<i className="fas fa-user-unlock"></i>&nbsp;
							<span className="m-2 mb-0 mt-0">
								{t("login", { ns: props.trNamespace })}
							</span>
						</button>
					</form>
				</div>
			</div>
		);
	}
	/**
	 * Forgot password function 
	 * @returns 
	 */
	async function forgotPassword() {
		// get el username value
		var username = document.getElementById("username").value;
		if (username == null || username == "") {
			sendToast("Login.SetUsername", "error", "Login");
			return;
		}
		if (!_userModel.checkEmail(username)) {
			return;
		}
		var data = {
			username: username
		};
		var res = await loginService.ResetPassword(data);
		if (res != null && res.success == true) {
			sendToast(
				t("login:password-reset.email-sent"),
				"success",
				t("common:success")
			);
		} else {
			sendToast(
				t("login:password-reset.email-not-sent"),
				"error",
				t("common:error")
			);
		}
		// disable button and remove click event
		document.getElementById("forgot-password").disabled = true;
		document.getElementById("forgot-password").onclick = null;
	}
	/**
	 * Render 
	 */
	return (
		<>
			<input type="hidden" id={componentId} />
			{setCard()}
		</>
	);
};

LoginForm.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	submitFunction: PropTypes.func
};

export default LoginForm;
