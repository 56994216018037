/**
 * REACT
 */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
/**
 * Services
 */
import { LoadDetailsData } from "../../data/services/load-data-service.js";
/**
 * Components
 */
import HeadMenu from "../common/headers/head-menu.js";
import CustomSpinner from "../common/shared/custom-spinner.js";
import CustomFooter from "../common/shared/custom-footer.js";
import CompanyImages from "../common/specific/company-images.js";
/**
 * Models
 */
import { setTheme, getTheme } from "../models/themes.js";
const _basketModel = require("../models/basket.js");
const _openingHoursModel = require("../models/opening-hours.js");
/**
 * Details
 * @param {*} _props
 * @returns
 */
const Details = (_props) => {
	var props = _props.properties;
	const componentId = "Details-" + Math.random().toString(36).substring(7);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [userEmail, setUserEmail] = useState(null);
	const [companyDatas, setCompanyDatas] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [canOrder, setCanOrder] = useState(false);
	const effectRan = useRef(false);
	// Specific
	setTheme(getTheme());
	/**
	 * useEffect
	 */
	useEffect(() => {
		props = _props.properties;
		if (!effectRan.current) {
			props = _props.properties;
			// Reload data on loaded state change.
			componentDidMount();
		}
		return () => {
			effectRan.current = true;
		};
	}, [loaded]);
	/**
	 * componentDidMount
	 */
	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	/**
	 * load All needed
	 */
	async function loadAll() {
		var data = await LoadDetailsData();
		if (data != null && data != undefined && data.companyName != null) {
			props.pageName = data.companyName;
			props.base64Image = data.companyDetails.CompanyLogo;
			setTheme(data.company.CompanyParams);
			setUserEmail(data.name);
			setCompanyDatas(data);
			setCanOrder(data.company.CompanyParams.CanOrder);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName} logged user ${userEmail}`
			);
			document.title = props.pageName;
			//_basketModel.setBasketPriceAndQty(null, data.companyDetails.Currency);
			// wait for seconds
			setTimeout(() => {
				setLoaded(true);
				setTimeout(() => {
					_basketModel.setBasketPriceAndQty({
						currency: data.companyDetails.Currency,
						cpid: data.company.CompanyId
					});
				}, 1000);
			}, 150);
		} else {
			setTimeout(async () => {
				await loadAll();
			}, 500);
		}
	}
	/**
	 * onError
	 * @param {*} error
	 */
	function onError(error) {
		if (process.env.REACT_APP_ENV == "development") {
			handleShowToast(
				"error",
				t("error", { ns: "common" }),
				`Company Details ${error}`
			);
		}
	}
	/**
	 * SetCardImage
	 * @param {*} _class
	 * @returns
	 */
	function setCardImage(_class) {
		if (_class == null) _class = "";
		if (props.base64Image != null && props.base64Image != "") {
			if (props.base64Image.startsWith("http")) {
				return (
					<img
						alt={props.pageName}
						src={`url:${props.base64Image}`}
						className={_class}
					/>
				);
			} else {
				return (
					<img
						alt={props.pageName}
						src={`data:image/png;base64,${props.base64Image}`}
						className={_class}
					/>
				);
			}
		}
	}
	/**
	 * setCardHeader
	 * @returns
	 */
	function setCardHeader() {
		return (
			<div className="row p-2">
				<div className="container-inline-center">
					{setCardImage("img-lg-responsive-logo border-rounded")}
					<br></br>
					&nbsp;<h3 className="">{props.pageName}</h3>
				</div>
				<hr></hr>
			</div>
		);
	}
	/**
	 * setCardFooter
	 * @returns
	 */
	function setCardFooter() {
		return (
			<div className="accordion" id="openingHoursAccordion">
				<div className="accordion-item  primary-bg-color">
					<h2 className="accordion-header" id="headingOne">
						<button
							className="accordion-button bg-primary-gradient"
							type="button"
							aria-expanded="false"
							onClick={() => {
								var el = document.getElementById("collapseOne");
								if (el != null) {
									el.classList.toggle("show");
								}
							}
							}
						>
							{t("openingHours", { ns: "common" })}
						</button>
					</h2>
					<div
						id="collapseOne"
						className="accordion-collapse collapse bg-light-dark"
						aria-labelledby="headingOne"
						data-bs-parent="#openingHoursAccordion"
					>
						<div className="accordion-body">{setOpeningHours("")}</div>
					</div>
				</div>
			</div>
		);
	}
	/**
	 * SetAddress
	 * @param {*} textClass
	 * @returns
	 */
	function setAddress(textClass) {
		var address = [];
		if (
			companyDatas != null &&
			companyDatas.company != null &&
			companyDatas.company.companyAddress != null &&
			companyDatas.company.companyAddress.length > 0
		) {
			if (companyDatas.company.companyAddress[0].Address1 != null)
				address.push(companyDatas.company.companyAddress[0].Address1);
			if (companyDatas.company.companyAddress[0].Address2 != null)
				address.push(companyDatas.company.companyAddress[0].Address2);
			if (companyDatas.company.companyAddress[0].Address3 != null)
				address.push(companyDatas.company.companyAddress[0].Address3);
			if (companyDatas.company.companyAddress[0].ZipCode != null)
				address.push(companyDatas.company.companyAddress[0].ZipCode);
			if (companyDatas.company.companyAddress[0].City != null)
				address.push(companyDatas.company.companyAddress[0].City);
		}
		var addressContent = null;
		// map address in addressContent as p
		addressContent = address.map((item, index) => {
			index++;
			return (
				<p className={textClass} key={`ad-${index}`}>
					{item}
				</p>
			);
		});
		return addressContent;
	}
	/**
	 * SetPhoneNumber
	 * @param {*} phoneTextClass
	 * @returns
	 */
	function setPhoneNumber(phoneTextClass) {
		// Add phone number if present
		var phoneNumber = null;
		if (
			companyDatas.company.companyAddress != null &&
			companyDatas.company.companyAddress.length > 0
		) {
			if (companyDatas.company.companyAddress[0].PhoneNumber != null) {
				var phNum = companyDatas.company.companyAddress[0].PhoneNumber;
				phoneNumber = (
					<p key={`ph-num`}>
						<a
							href={`tel:${phNum}`}
							className={`${phoneTextClass} p-2 border-rounded`}
						>
							<b>
								<i className="fa fa-phone"></i>
								&nbsp;{phNum}
							</b>
						</a>
					</p>
				);
			}
			return phoneNumber;
		}
	}
	/**
	 * SetOpeningStatus
	 * @returns
	 */
	function setOpeningStatus() {
		// Get client actual time
		var now = new Date();
		var day = now.toLocaleString("en-us", { weekday: "long" });
		// Get number of day of week
		var dayOfWeek = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday"
		];
		// Get index of day of week
		var dayIndex = dayOfWeek.indexOf(day);
		var open = false;
		var openingHours = companyDatas.company.CompanyParams.OpeningHours;
		var openElement = null;
		if (openingHours != null && openingHours.length > 0) {
			// Find openingHours where Day = dayIndex
			var thisDay = openingHours.find((x) => x.Day == dayIndex);
			var nextHour = null;
			var nextDay = null;
			// Get opening hours of the day
			if (thisDay != null) {
				var ohmodel = _openingHoursModel.getOpeningHours({
					t: t,
					openingHours: openingHours
				});
				open = ohmodel.open;
				nextHour = ohmodel.nextHour;
				nextDay = ohmodel.nextDay;
				if (open) {
					var nextHourStr = null;
					if (nextHour != null) {
						nextHourStr = (
							<p className="badge    animate slide delay-15">
								{t("next-opening", { ns: "details" })}&nbsp;
								{t("at", { ns: "details" })}&nbsp;{nextHour}
							</p>
						);
						// Open today but close right now
						open = false;
					}
				} else {
					var nextDayStr = null;
					if (nextDay != null && nextHour != null) {
						nextDayStr = (
							<center>
								<p className="badge   animate slide delay-15">
									{t("next-opening", { ns: "details" })}&nbsp;{nextDay}&nbsp;
									{t("at", { ns: "details" })}&nbsp;{nextHour}
								</p>
							</center>
						);
					}
				}
				openElement = (
					<center>
						<p
							className={`badge ${open ? "text-bg-success" : "bg-danger"
								}  animate splat delay-8`}
						>
							{open
								? t("open", { ns: "common" })
								: t("closed", { ns: "common" })}
						</p>
						{nextDayStr}
						{nextHourStr}
					</center>
				);
			}

			return openElement;
		}
	}

	/**
	 * SetOpening
	 * @param {*} textClass
	 * @returns
	 */
	function setOpeningHours(textClass) {
		var openingHours = companyDatas.company.CompanyParams.OpeningHours;
		var openingDatas = [];
		var startDay = 0;
		if (process.env.REACT_APP_IS_FRENCH) startDay = 1;
		if (openingHours != null && openingHours.length > 0) {
			for (var i = startDay; i < openingHours.length; i++) {
				openingDatas.push(setDaysAndHours(openingHours[i], i, textClass));
			}
			if (process.env.REACT_APP_IS_FRENCH) {
				openingDatas.push(setDaysAndHours(openingHours[0], i, textClass));
			}
		}
		return openingDatas;
	}
	/**
	 * SetDaysAndHours
	 * @param {*} openingHours
	 * @param {*} index
	 * @param {*} textClass
	 * @returns
	 */
	function setDaysAndHours(openingHours, index, textClass) {
		if (openingHours.Open) {
			var hours = openingHours.Hours;
			var _hours = [];
			for (var j = 0; j < hours.length; j++) {
				// Start = 'hh:mm', End = 'hh:mm' Covert to time
				var start = hours[j].Start;
				var end = hours[j].End;
				var startDate = new Date();
				var endDate = new Date();
				startDate.setHours(start.split(":")[0]);
				startDate.setMinutes(start.split(":")[1]);
				endDate.setHours(end.split(":")[0]);
				endDate.setMinutes(end.split(":")[1]);
				_hours.push({ Start: start, End: end });
			}
			//_hours ordered by startDate
			_hours.sort((a, b) => a.Start - b.Start);
			var _hoursStr = [];
			for (var k = 0; k < _hours.length; k++) {
				// get _hours[k].Start time only: hh:mm
				_hoursStr.push(
					<span className="badge txt-dark " key={"b-" + k}>
						{_hours[k].Start + " - " + _hours[k].End}
					</span>
				);
			}
			return (
				<div key={`oh-${index}`} style={{ margin: "4px 0" }}>
					<div className="container-inline-spaced">
						<span className={`${textClass}  txt-dark`}>
							<b>{t(`days.${openingHours.Day}`)}&nbsp;</b>
						</span>
						<span className={`badge  text-bg-success ${textClass}`}>
							{" "}
							{t("open", { ns: "common" })}
						</span>
					</div>
					<div className="d-flex justify-content-start">{_hoursStr}</div>
					<hr></hr>
				</div>
			);
		} else {
			return (
				<div key={`oh-${index}`} style={{ margin: "4px 0" }}>
					<div className="container-inline-spaced">
						<span className={`${textClass}  txt-dark`}>
							<b>{t(`days.${openingHours.Day}`)}&nbsp;</b>
						</span>
						<span className={`badge  bg-danger ${textClass}`}>
							{t("closed", { ns: "common" })}
						</span>
					</div>
					<hr></hr>
				</div>
			);
		}
	}
	/**
	 * SetCardBody
	 * @returns
	 */
	function setCardBody() {
		return (
			<center>
				{setAddress("")}
				{setPhoneNumber("bg-warning")}
			</center>
		);
	}
	/**
	 * SetCard
	 * @returns
	 */
	function setCard() {
		return (
			<div
				className="col-xs-12 col-md-6 col-lg-4 p-2 animate blur"
				key={companyDatas.Id}
			>
				<div className="card h-100">
					<div className="card-header bg-primary-gradient">
						{setCardHeader()}
					</div>
					<div className="card-body bg-secondary">
						{setCardBody()}
						{setOpeningStatus()}
						<div className="m-4"></div>
					</div>
					<div className="card-footer bg-secondary">{setCardFooter()}</div>
				</div>
			</div>
		);
	}

	function setCarousel() {
		return <CompanyImages properties={{ pageName: "images" }}></CompanyImages>;
	}

	/**
	 * Return component
	 */
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App ">
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 app-primary-bg-color txt-primary ",
					textClass: "",
					menuButtonClass: "txt-primary m-2",
					showOrders: canOrder
				}}
			/>
			{/* <PageHeader properties={props} /> */}
			<div className="content-container content-margin-top" key={componentId}>
				<div className="content-margin-top" key={componentId}>
					<center>{setCard()}</center>
					<div className="p-4">{setCarousel()}</div>
				</div>
			</div>
			<div className="app-separator-large"></div>
			<CustomFooter></CustomFooter>
		</div>
	);
};

Details.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	backgroundImage: PropTypes.string,
	translatePageTitle: PropTypes.bool,
	showHeaderTooltip: PropTypes.bool,
	base64Image: PropTypes.string
};

export default Details;
