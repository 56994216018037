// Get number of day of week
const dayOfWeek = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday"
];
/**
 * Get Opening Hours
 * @param {*} param0
 * @returns
 */
function getOpeningHours({ openingHours, t, chosenDate }) {
	// Get client actual time
	if (openingHours == null) {
		openingHours = [];
	}
	var now = new Date();
	if (chosenDate != null) {
		now = new Date(chosenDate);
	}
	var day = now.toLocaleString("en-us", { weekday: "long" });

	// Get index of day of week
	var dayIndex = dayOfWeek.indexOf(day);
	var open = false;
	var thisDay = openingHours.find((x) => x.Day == dayIndex);
	var nextHour = null;
	var nextDay = null;
	var nextDate = null;
	var actualHours = null;
	var allHours = [];
	// Get opening hours of the day
	if (thisDay != null) {
		if (thisDay.Open) {
			allHours = thisDay.Hours;
			open = true;
			var maxEndHour = null;
			var lastCloseHour = null;
			for (var i = 0; i < thisDay.Hours.length; i++) {
				var dtS = new Date(now);
				dtS.setHours(thisDay.Hours[i].Start.split(":")[0]);
				dtS.setMinutes(thisDay.Hours[i].Start.split(":")[1]);
				var dtE = new Date(now);
				dtE.setHours(thisDay.Hours[i].End.split(":")[0]);
				dtE.setMinutes(thisDay.Hours[i].End.split(":")[1]);
				var dtNh = new Date(now);
				if (nextHour != null) {
					dtNh.setHours(nextHour.split(":")[0]);
					dtNh.setMinutes(nextHour.split(":")[1]);
				}
				var start = thisDay.Hours[i].Start;
				if (actualHours == null || start < actualHours.start) {
					actualHours = {
						start: thisDay.Hours[i].Start,
						end: thisDay.Hours[i].End
					};
				}
				if (now >= dtS && now <= dtE) {
					nextHour = null;
					lastCloseHour = null;

					break;
				}
				if (dtS > now) {
					if (nextHour == null) {
						nextHour = start;
					} else {
						if (dtNh > dtS) {
							nextHour = start;
						}
					}
					nextDate = new Date(now);
					nextDate.setHours(nextHour.split(":")[0]);
					nextDate.setMinutes(nextHour.split(":")[1]);
				}
				if (maxEndHour == null || maxEndHour < dtE) {
					maxEndHour = dtE;
				}
			}
			if (now > maxEndHour) {
				lastCloseHour = dtE;
			}
			if (lastCloseHour != null) {
				// check if lastCloseHour is < now
				if (now > lastCloseHour) {
					open = false;
					var arr = setClosed(openingHours, dayIndex + 1, t, chosenDate);
					nextDay = arr[0];
					nextHour = arr[1];
					nextDate = arr[2];
					actualHours = arr[3];
				}
			}
		} else {
			open = false;
			var arrHour = setClosed(openingHours, dayIndex, t, chosenDate);
			nextDay = arrHour[0];
			nextHour = arrHour[1];
			nextDate = arrHour[2];
			actualHours = arrHour[3];
		}
	}
	return {
		open: open,
		nextDay: nextDay,
		nextHour: nextHour,
		nextDate: nextDate,
		actualHours: actualHours,
		allHours: allHours
	};
}
/**
 * SetClosed
 * @param {*} openingHours
 * @param {*} dayIndex
 * @returns
 */
function setClosed(openingHours, dayIndex, t, chosenDate = null) {
	var nextDay = null;
	var nextHour = null;
	var endHour = null;
	var nextDate = new Date();
	if (chosenDate != null) {
		nextDate = new Date(chosenDate);
	}
	var day = nextDate.toLocaleString("en-us", { weekday: "long" });
	var actualHours = null;
	// get actual day index
	for (var j = dayIndex; j < openingHours.length; j++) {
		if (openingHours[j].Open) {
			nextDay = t(`days.${openingHours[j].Day}`);
			nextHour = setNextHour(openingHours[j]);
			endHour = setNextEndHour(openingHours[j]);
			actualHours = {
				start: nextHour,
				end: endHour
			};

			// with the day index get next date with this day index
			// get future date of next day
			// get day index of nextDate
			var ndDayIndex = j - dayOfWeek.indexOf(day);

			nextDate.setDate(nextDate.getDate() + ndDayIndex);

			// set hour to nextHour
			nextDate.setHours(nextHour.split(":")[0], nextHour.split(":")[1], 0, 0);
			break;
		}
	}
	if (nextDay == null && dayIndex > 0) {
		for (var l = 0; l < dayIndex; l++) {
			if (openingHours[l].Open) {
				nextDay = t(`days.${openingHours[l].Day}`);
				nextHour = setNextHour(openingHours[l]);
				endHour = setNextEndHour(openingHours[l]);
				actualHours = {
					start: nextHour,
					end: endHour
				};
				// get future date of next day
				ndDayIndex = j - dayOfWeek.indexOf(day);

				nextDate.setDate(nextDate.getDate() + ndDayIndex + 1);
				// set hour to nextHour
				nextDate.setHours(nextHour.split(":")[0], nextHour.split(":")[1], 0, 0);
				break;
			}
		}
	}

	return [nextDay, nextHour, nextDate, actualHours];
}
/**
 * GetNextHour
 */
function setNextHour(openingHours) {
	var nextHour = null;
	for (var i = 0; i < openingHours.Hours.length; i++) {
		if (nextHour == null || nextHour >= openingHours.Hours[i].Start) {
			nextHour = openingHours.Hours[i].Start;
		}
	}
	return nextHour;
}
/**
 * GetNextEndHour
 * @param {*} openingHours
 * @returns
 */
function setNextEndHour(openingHours) {
	var nextHour = null;
	for (var i = 0; i < openingHours.Hours.length; i++) {
		if (nextHour == null || nextHour > openingHours.Hours[i].Start) {
			nextHour = openingHours.Hours[i].End;
		}
	}
	return nextHour;
}
/**
 * Set Delivery Hours
 * @param {*} param0
 * @returns
 */
function setDeliveryHours({ openingHours, companyDatas, day }) {
	// if openingHours.open, get actualHours
	var resultHours = [];

	if (openingHours.open) {
		var actualHours = [];

		for (var i = 0; i < openingHours.allHours.length; i++) {
			var dt = new Date();
			if (day != null) {
				dt = new Date(day);
			}
			actualHours = openingHours.allHours[i];
			// Get time now
			var timeNow = dt.getTime();
			// Get time of actualHours.end
			var timeEnd = new Date(dt);

			timeEnd.setHours(actualHours.End.split(":")[0]);
			timeEnd.setMinutes(actualHours.End.split(":")[1]);
			timeEnd.setSeconds(0);
			// companyActualDeliveryTime is in minutes add it to time now and check if it is < timeEnd
			var targetHour = timeNow + companyDatas.ActualDeliveryTime;

			if (targetHour > timeEnd) {
				actualHours = [];
			} else {
				// Create hours array from start to end step is companyDatas.ActualDeliveryTime
				// If item hours > timeEnd, remove it, if item hours < dateNow, remove it
				var hours = [];
				// Set start = dt with time = actualHours.start
				var start = new Date(dt);
				start.setHours(actualHours.Start.split(":")[0]);
				start.setMinutes(actualHours.Start.split(":")[1]);
				start.setSeconds(0);

				var end = new Date(dt);
				end.setHours(actualHours.End.split(":")[0]);
				end.setMinutes(actualHours.End.split(":")[1]);
				end.setSeconds(0);

				var target = new Date(start);
				while (target <= end) {
					hours.push(setTimeFromDate(target));
					target.setMinutes(
						target.getMinutes() + companyDatas.ActualDeliveryTime
					);
				}
				// remove all hours < timeNow
				hours.forEach((hour) => {
					dt.setHours(hour.split(":")[0]);
					dt.setMinutes(hour.split(":")[1]);
					// Check if time now to dt is >= companyDatas.ActualDeliveryTime
					// timespan in minutes
					var ndt = new Date(dt);
					var timeSpan = ndt.getTime() - timeNow;
					if (timeSpan >= companyDatas.ActualDeliveryTime * 60000) {
						resultHours.push(hour);
					}
				});
			}
		}
	}
	// order resultHours by time
	resultHours = resultHours.sort((a, b) => {
		var aTime = a.split(":");
		var bTime = b.split(":");
		if (aTime[0] < bTime[0]) {
			return -1;
		}
		if (aTime[0] > bTime[0]) {
			return 1;
		}
		if (aTime[1] < bTime[1]) {
			return -1;
		}
		if (aTime[1] > bTime[1]) {
			return 1;
		}
		return 0;
	});
	return resultHours;
}
/**
 * SetTimeFromDate
 * @param {*} date
 * @returns
 */
function setTimeFromDate(date) {
	// Date is a a date object
	var hours = date.getHours();
	if (hours < 10) {
		hours = `0${hours}`;
	}
	var minutes = date.getMinutes();
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	var time = `${hours}:${minutes}`;
	return time;
}

module.exports = {
	getOpeningHours,
	setDeliveryHours
};
