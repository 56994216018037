export function setVegan(item, _model, t) {
    if (_model.showOption(item, "Vegan")) {
        return (
            <span
                className={`badge bg-success  m-2 mb-1 mt-1 p-2`}
                data-bs-toggle="tooltip"
                title={t("menu-prop.vegan", { ns: "menu" })}
            >
                <i className="fa fa-solid fa-leaf"></i>
                <i className="">&nbsp;{t("menu-prop.vegan", { ns: "menu" })}</i>
            </span>
        );
    }
}
/**
 * Set the nutriscore
 * @param {*} item
 * @returns
 */
export function setNutriscore(item, _model, props, nutriscore) {
    if (_model.show(item, props.showNutriscore, "ShowNutriscore")) {
        if (item.Nutriscore < 1) item.Nutriscore = 1;
        if (item.Nutriscore > 5) item.Nutriscore = 5;
        return (
            <div>
                <div className="m-2  mb-1 mt-1">
                    <img
                        alt={item.Name}
                        src={nutriscore[item.Nutriscore - 1]}
                        className="img-sm-responsive"
                    />
                </div>
            </div>
        );
    }
}

export function setVege(item, _model, t) {
    if (_model.showOption(item, "Vege")) {
        return (
            <span
                className={`badge bg-success  m-2 mb-1 mt-1 p-2`}
                data-bs-toggle="tooltip"
                title={t("menu-prop.vege", { ns: "menu" })}
            >
                <i className="fa fa-solid fa-salad"></i>
                <i className="">&nbsp;{t("menu-prop.vege", { ns: "menu" })}</i>
            </span>
        );
    }
}
export function setHalal(item, _model, t) {
    if (_model.showOption(item, "Halal")) {
        return (
            <span
                className={`badge bg-warning  m-2 mb-1 mt-1 p-2`}
                data-bs-toggle="tooltip"
                title={t("menu-prop.halal", { ns: "menu" })}
            >
                <i className="fa fa-solid fa-steak"></i>
                <i className="">&nbsp;{t("menu-prop.halal", { ns: "menu" })}</i>
            </span>
        );
    }
}
export function setSpicy(item, _model, t) {
    if (_model.showOption(item, "Spicy")) {
        return (
            <span
                className={`badge bg-danger  m-2 mb-1 mt-1 p-2`}
                data-bs-toggle="tooltip"
                title={t("menu-prop.spicy", { ns: "menu" })}
            >
                <i className="fa fa-solid fa-pepper-hot"></i>
                <i className="">&nbsp;{t("menu-prop.spicy", { ns: "menu" })}</i>
            </span>
        );
    }
}
/**
 * Set the home made
 * @param {*} item
 * @returns
 */
export function setHomeMade(item, _model, t) {
    if (_model.showOption(item, "HomeMade")) {
        return (
            <span
                className={`badge bg-info m-2 mb-1 mt-1 p-2`}
                data-bs-toggle="tooltip"
                title={t("menu-prop.home-made", { ns: "menu" })}
            >
                <i className={`fa fa-solid fa-home`}></i>
                <i>&nbsp;{t("menu-prop.home-made", { ns: "menu" })}</i>
            </span>
        );
    }
}


/**
     * Set the product description					</div>
	
     * @param {*} item
     * @returns
     */
export function setDescription(item, _model, showProductDescription) {
    if (
        _model.show(item, showProductDescription, "ShowProductDescription")
    ) {
        return (
            <div className="m-2  mb-0 mt-1 text-center">
                <h5>{item.ProductDescription ?? ""}</h5>
            </div>
        );
    }
}
/**
 * Set the ingredients
 * @param {*} item
 * @returns
 */
export function setIngredients(item, _model, showIngredients, t) {
    if (_model.show(item, showIngredients, "ShowIngredients")) {
        var ingredients = null;
        if (item.ProductComplements != null && item.ProductComplements.length > 0) {
            ingredients = _model.prepareIngredientList(item);
            if (ingredients != null) {
                // sort ingredients by PositionIngredient if PositionIngredient is null set it to 9999
                ingredients = ingredients.sort((a, b) => {
                    if (a.PositionIngredient === b.PositionIngredient) {
                        // Sort by name
                        if (a.IngredientName < b.IngredientName) {
                            return -1;
                        }
                    }
                    return a.PositionIngredient - b.PositionIngredient;
                });

                // join ingredients with ', '
                item.Ingredients = ingredients.join(", ");
                return (
                    <div className="m-2  mb-1 mt-1">
                        <div className="row ">
                            <div className="col-sm-12 ">
                                <span className="badge bg-warning">
                                    {t("ingredients.title", { ns: "menu" })}
                                </span>
                                <p className="m-2  mb-0 mt-1">
                                    <i>{item.Ingredients}</i>
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
/**
 * Set the allergens
 * @param {*} item
 * @returns
 */
export function setAllergens(item, _model, showAllergens, t) {
    if (_model.show(item, showAllergens, "ShowAllergens")) {
        var allergens = _model.prepareAllergenList(item);
        if (allergens.length > 0) {
            // join allergens with ', '
            var allergen = allergens.join(", ");
            return (
                <div className="m-2 mb-1 mt-1">
                    <span className="badge bg-danger center">
                        {t("allergens.title", { ns: "menu" })}
                    </span>
                    <div className="row ">
                        <div className="col-sm-12 ">
                            <p className="m-2  mb-0 mt-1">
                                <i>{allergen}</i>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return <div></div>;
}

export function setPromoTag(item, isPromoted) {
    var _className1 = "child-superpose2 ";
    var _className2 = "circle-text ";
    if (isPromoted) {
        _className1 = "child-superpose1";
        _className2 = "circle-text circle-text-promo";
    }
    var tag = null;
    // Check if product.ProductOptions[0] has Tag
    if (item.ProductOptions != null && item.ProductOptions.length > 0) {
        var productOption = item.ProductOptions[0];
        var tagColor = "warning";
        if (productOption.TagColor != null && productOption.TagColor != "") {
            tagColor = productOption.TagColor;
        }
        if (productOption.Tag != null && productOption.Tag != "") {
            tag = (
                <div className={_className1 + " w-100"}>
                    <h5 className={`${_className2} circle-text bg-${tagColor}`}>
                        {productOption.Tag}
                    </h5>
                </div>
            );
        }
    }
    return tag;
}

export function setQty(add, selectedVariant, selectedAddIngredients, selectedAdditionalProducts) {
    var el = document.getElementById("total-qty");
    if (el != null) {
        var qty = getQty();
        if (add) {
            qty++;
        } else {
            if (qty > 1) {
                qty--;
            }
        }
        el.innerHTML = qty;
        setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts);
    }
}
export function setTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts, openPrice = true) {
    // get element total-price
    var totalPrice = document.getElementById("total-price");
    var totalPriceDiv = document.getElementById("total-price-div");
    if (totalPriceDiv != null && openPrice) {
        totalPriceDiv.style.display = "block";
    }
    // set visible
    if (totalPrice != null) {
        var ttUnit = getTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts);
        var qty = document.getElementById("total-qty").innerHTML;
        totalPrice.innerHTML = (parseFloat(ttUnit) * parseInt(qty)).toFixed(2);
    }
}
export function getTotalPrice(selectedVariant, selectedAddIngredients, selectedAdditionalProducts) {
    var price = 0;
    var variantPrice = selectedVariant.variantPrice;
    var addedIngredientsPrice = 0;
    for (var i = 0; i < selectedAddIngredients.length; i++) {
        addedIngredientsPrice += parseFloat(selectedAddIngredients[i].value);
    }
    for (i = 0; i < selectedAdditionalProducts.length; i++) {
        addedIngredientsPrice += parseFloat(selectedAdditionalProducts[i].productPrice ?? 0);
    }
    // Set total
    price = parseFloat(variantPrice) + addedIngredientsPrice;
    return price.toFixed(2);
}
export function getQty() {
    var qty = 1;
    var el = document.getElementById("total-qty");
    if (el != null) {
        qty = parseInt(el.innerHTML);
    }
    return qty;
}

