import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/front/login/password-reset";

function ResetPassword() {
	const route = `${baseUrl}/`;
	return route;
}
export default {
	ResetPassword
};
