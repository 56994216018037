const lsName = "guests";
function checkGuest(cpid) {
	var guest = getGuest(cpid);
	return guest;
}
function setGuest(guest, cpid) {
	addGuest(guest, cpid);
}

function getGuest(cpid) {
	var item = null;
	// if localStorage guest exists
	var guests = getGuests();
	// check if guests has item with cpid and context
	if (guests != null) {
		try {
			var guest = findItem(guests, cpid);
			item = guest;
			if (item) {
				item.guest = JSON.parse(item.guest);
			}
		} catch {
			// remove guests
			localStorage.removeItem(lsName);
			getGuests();
		}
	}
	return item?.guest;
}

function getGuests() {
	// get guestItem from localStorage
	var guests = localStorage.getItem(lsName);
	// if guestItem is null create new []
	if (guests == null) {
		guests = [];
	} else {
		guests = JSON.parse(guests);
	}
	return guests;
}
function addGuest(guest, cpid) {
	var guests = getGuests();
	var newGuest = {
		cpid: cpid,
		context: process.env.REACT_APP_NAME,
		guest: guest
	};
	if (guests.length > 0) {
		var item = findItem(guests, cpid);
		if (item != null) {
			guests.splice(guests.indexOf(item), 1);
		}
	}
	// push guest to guestItem
	guests.push(newGuest);
	// save paymentState to localStorage
	localStorage.setItem(lsName, JSON.stringify(guests));
}

function findItem(list, cpid) {
	var item = null;
	try {
		item = list.find(
			(x) => x.cpid === cpid && x.context === process.env.REACT_APP_NAME
		);
	} catch {
		// remove guests
		localStorage.removeItem(lsName);
		getGuests();
	}
	return item;
}

module.exports = {
	checkGuest,
	setGuest
};
