/**
 * ! Change HeadMenu by your component name
 */

import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
//import { LoadData } from "../../../data/services/load-data-service.js";
//import { LogoBo2_80Animation } from "../../../properties/animations/logos-animations.js";
import { setTheme, getTheme } from "../../models/themes.js";
import OrderThumbnail from "../specific/order-thumbnail.js";
import SuggestedProducts from "./suggested-products.js";

const _companyModel = require("../../models/company.js");
const _basketModel = require("../../models/basket.js");
const _userModel = require("../../models/user.js");
import LoginForm from "../forms/login-form.js";
import CreateAccount from "../forms/create-account.js";
import bootstrapHN from "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {
	logingWithLocalDb,
	logout
} from "../../../data/services/load-data-service.js";


const DynamicComponent = (props) => {
	return (
		<SuggestedProducts
			properties={props}
		></SuggestedProducts>
	);
};

const HeadMenu = (_props) => {
	var props = _props.properties;
	props.showLogin = props.showLogin ?? true;
	const ticks = new Date().getTime();
	const componentId = "head-menu-" + ticks;
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [userEmail, setEmail] = useState(null);
	const [loaded, setLoadedState] = useState(false);
	const [showComponent, setShowComponent] = useState(false);

	var custom = process.env.REACT_APP_COMPANY_ID
		? process.env.REACT_APP_LOGO
		: require("../../../assets/commons/menu-128.png");
	const [setLogo, setNewLogo] = useState(props.image ?? custom);

	setTheme(getTheme());
	var _cpLocal = _companyModel.getCompanyDatas();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) {
				onError(e);
			}
		}
	}
	async function loadAll() {
		setTheme(getTheme());
		setNewLogo(custom);
		var logged = await logingWithLocalDb();
		if (logged && global.LOGGED_USER != null) {
			setEmail(global.LOGGED_USER.Email);
		}
		await loadProducts();

	}

	async function loadProducts() {
		setLoadedState(true);
	}

	const start = (_logo, animate) => {
		if (props.image == null || props.image == "") _logo = false;
		var imgComp = (imgComp = (
			<img src={setLogo} className="img-s-responsive border-rounded"></img>
		));
		if (_logo) {
			if (props.image == "null" || props.image.startsWith("http")) {
				imgComp = <img src={setLogo} className="img-s-responsive"></img>;
			} else {
				imgComp = (
					<img
						src={`data:image/png;base64,${props.image}`}
						className="img-s-responsive border-rounded m-0"
					></img>
				);
			}
		}
		return (
			<a
				href={`/${_cpLocal.companyId}/menu`}
				className={`${props.textClass} navbar-brand container-inline link-header-title ${animate ? "animate glow delay-1 nav-height-100}" : ""
					}`}
			>
				{imgComp}
				<h5 className="txt-header-title txt-primary font-bold left-0 m-1 mb-0 mt-0">
					{props.title}
				</h5>
			</a>
		);
	};
	const end = (
		<hr></hr>
	);
	function activateAccountMenu() {
		var el = document.getElementsByClassName("dropdown-menu-account");
		//if el has class show remove it
		if (el[0].classList.contains("show")) {
			el[0].classList.remove("show");
		} else {
			el[0].classList.add("show");
		}
	}
	function setMenuItems() {
		if (props.showMenu == false) return "";
		var account = null;
		//if (props.showOrders) {
		if (userEmail != null) {
			account = (
				<li className="nav-item dropdown m-4 mb-0 mt-0 animate pop delay-4">
					<a
						className="nav-link txt-primary"
						href="#"
						role="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={() => {
							activateAccountMenu();
						}}
					>
						<span className="h2-mob">
							<i className={` font-bold fas fa-user-gear`}></i>
							&nbsp;
							{userEmail}
							<i className={` m-2 mt-0 mb-0 font-bold fas fa-caret-down`}></i>
						</span>
					</a>
					<ul className="dropdown-menu dropdown-menu-account bg-primary-gradient  txt-primary w-100">
						<li>
							<a
								className="dropdown-item txt-primary"
								href={`/${_cpLocal.companyId}/user-profile`}
								onClick={() => {
									activateAccountMenu();
								}}
							>
								<span className="h2-mob">
									<i className={` font-bold fas fa-address-card`}></i>
									&nbsp;
									{t("common:profile")}
								</span>
							</a>
						</li>
						<li>
							<a
								className="dropdown-item txt-primary"
								href={`/${_cpLocal.companyId}/user-orders`}
								onClick={() => {
									activateAccountMenu();
								}}
							>
								<span className="h2-mob">
									<i className={` font-bold fas fa-basket-shopping`}></i>
									&nbsp;
									{t("orders:orders.title")}
								</span>
							</a>
						</li>
						<li>
							<hr className="dropdown-divider txt-primary" />
						</li>
						<li>
							<a
								className="dropdown-item txt-primary"
								href="#"
								onClick={async () => {
									activateAccountMenu();
									var loggedOut = await logout();
									if (loggedOut) {
										window.location.href = `/${_cpLocal.companyId}/menu`;
									}
								}}
							>
								<span className="h2-mob">
									<i className={` font-bold fas fa-user-large-slash`}></i>
									&nbsp;
									{t("common:logout")}
								</span>
							</a>
						</li>
					</ul>
				</li>
			);
		} else {
			if (props.showLogin) {
				account = (
					<li className="animate pop delay-4">
						<a
							className="nav-link active txt-primary trn"
							data-bs-toggle="modal"
							data-bs-target="#loginModal"
						>
							<span className="h2-mob">
								<i className={` font-bold fa-sharp fa-user-large-slash`}></i>
								&nbsp;{t("login", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
				);
			}
		}
		//}

		var uls = (
			<>
				<ul
					className="navbar-nav me-auto mb-2 mb-lg-0 p-4 pb-0 pt-0 w-100"
					style={{ left: "15px !important", marginLeft: "0 !important" }}
				>
					<li className="animate pop">
						<a
							className="nav-link active txt-primary trn"
							href={`/${_cpLocal.companyId}/menu`}
						>
							<span className="h2-mob">
								<i
									className={` font-bold fa-sharp fa-solid fa-plate-utensils`}
								></i>
								&nbsp;{t("menu", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
					<li className="animate pop delay-2">
						<a
							className="nav-link active txt-primary trn"
							href={`/${_cpLocal.companyId}/details`}
						>
							<span className="h2-mob">
								<i className={` font-bold fa-sharp fa-solid fa-user-chef`}></i>
								&nbsp;{t("details", { ns: props.trNamespace })}
							</span>
						</a>
					</li>

					{account}
					<li className="animate pop delay-5">
						<a className="nav-link active trn" href={`/${_cpLocal.companyId}/`}>
							{end}
						</a>
					</li>
				</ul>
			</>
		);
		return uls;
	}

	function onError(error) {
		sendToast(
			`From Head Menu ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}

	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}

	function setOrderedProducts() {
		// disable order-button
		var orderButton = document.getElementById("order-button");
		if (orderButton != null) {
			orderButton.removeAttribute("disabled");
		}
		var content = setProductOrderThumbnail();
		var orderContent = document.getElementById("order-content");
		if (orderContent != null) {
			orderContent.innerHTML = renderToString(content);
			// Get all element with class btn-minus-qty
			var btnMinus = document.getElementsByClassName("btn-minus-qty");
			var btnPlus = document.getElementsByClassName("btn-plus-qty");
			var productsBasket = [];
			var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
			productsBasket = basket.products;
			// Set event listener on each element
			for (var i = 0; i < btnMinus.length; i++) {
				btnMinus[i].addEventListener("click", function () {
					var itemId = this.getAttribute("data-item-id");
					// find item in basket where item.BasketId == itemId
					var item = productsBasket.find((x) => x.BasketId == itemId);

					setQty(false, item);
				});
			}
			for (i = 0; i < btnPlus.length; i++) {
				btnPlus[i].addEventListener("click", function () {
					var itemId = this.getAttribute("data-item-id");
					var item = productsBasket.find((x) => x.BasketId == itemId);

					setQty(true, item);
				});
			}
			var deleteButtons =
				document.getElementsByClassName("btn-order-delete");
			for (i = 0; i < deleteButtons.length; i++) {
				deleteButtons[i].addEventListener("click", function () {
					// if confirm
					if (confirm(t("orders.products.delete", { ns: "orders" }))) {
						var itemId = this.getAttribute("data-item-id");
						var item = productsBasket.find((x) => x.BasketId == itemId);
						_basketModel.deleteBasketProduct(
							item.BasketId,
							_cpLocal.companyId,
							_cpLocal.currency
						);
						// get element {`order-item-${props.item.BasketId}`}
						var orderItem = document.getElementById(
							`order-item-${item.BasketId}`
						);
						// remove element
						orderItem.remove();
						// get basket
						var __basket = _basketModel.getCpyBasket(
							_cpLocal.companyId
						);
						var __products = __basket.products;
						if (__products.length == 0) {
							// disable order-button
							var orderButton = document.getElementById("order-button");
							if (orderButton != null) {
								orderButton.setAttribute("disabled", "disabled");
							}
						}
					}
				});
			}
		}
	}

	function setBasket() {
		if (props.showOrders == false) return "";
		return (
			<h5 className="nav-item dropdown animate pop delay-1 mb-0 mt-0">
				<a
					data-id="basket-icon-main"
					className="nav-link active txt-primary trn"
					data-bs-toggle="offcanvas"
					href="#offcanvasBasket"
					role="button"
					onClick={() => {
						setOrderedProducts();
					}}
					aria-controls="offcanvasBasket"
				>
					<span className="container-inline-right">
						<div className="parent-superpose">
							<span
								className="position-absolute top-0 start-100 translate-middle badge bg-danger font-sm rounded-circle"
								data-id="basket-count-articles"
							></span>
							<span className="">
								<i className={`fa-sharp fa-solid fa-basket-shopping`}></i>
							</span>
						</div>
						<span
							className="badge bg-info m-2 font-sm mb-0 mt-0"
							data-id="basket-count"
						></span>
					</span>
				</a>
			</h5>
		);
	}

	function setProductOrderThumbnail() {
		var productsBasket = [];
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		productsBasket = basket.products;
		return productsBasket.map((item, count) => {
			count++;
			return (
				<OrderThumbnail
					key={count}
					properties={{ item: item, trNamespace: "orders", count: count }}
				></OrderThumbnail>
			);
		});
	}

	function setOffCanvas() {
		if (props.showOrders == false) return "";
		return (
			<div
				className="offcanvas offcanvas-end"
				tabIndex="-1"
				id="offcanvasBasket"
				aria-labelledby="offcanvasBasketLabel"
			>
				<div className="offcanvas-header bg-primary">
					<h4 className="offcanvas-title font-bold" id="offcanvasBasketLabel">
						<div className="container-inline m-2 mb-0 mt-0">
							<div className="container-inline">
								<span>{t("common:basket")}</span>
								<span
									className="m-2 mb-0 mt-0 badge bg-danger font-sm rounded-circle"
									data-id="basket-count-articles"
								></span>
							</div>
							<button
								className=" m-4 mb-0 mt-0 btn btn-danger btn-sm"
								type="button"
								onClick={() => {
									if (confirm(t("orders:delete-all", { ns: "orders" }))) {
										_basketModel.deleteAllBasketProducts(
											_cpLocal.companyId,
											_cpLocal.currency
										);
										// remove all children of order-content
										var orderContent = document.getElementById("order-content");
										if (orderContent != null) {
											orderContent.innerHTML =
												"<h6>" + t("orders:empty-basket") + "</h6>";
										}
									}
								}}
							>
								<i className="fa fa-trash"></i>
								<span className="m-2 mb-0 mt-0">
									{t("orders:delete-all-basket")}
								</span>
							</button>
						</div>
					</h4>

					<button
						type="button"
						className="btn-close btn-secondary"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div className="offcanvas-body bg-light">
					<div id="order-content"></div>
				</div>
				<div className="offcanvas-footer bg-primary">
					<div className="row m-4  h-100">
						<div className="col">
							<div className="row ">
								<h6 className="font-bold" id="offcanvasBasketLabel">
									<span>{t("orders:total-price")}</span>
								</h6>
							</div>
							<div className="row">
								<h4>
									<span className="font-bold" data-id="basket-count"></span>
								</h4>
							</div>
						</div>
						<div className="col">
							<button
								className="btn btn-info font-bold float-end centered m-2 w-100"
								id="order-button"
								data-bs-dismiss="offcanvas"
								type="button"
								onClick={() => {

									var modal = new bootstrapHN.Modal(
										document.getElementById("suggestionModal")
									);
									modal.show();
									setTitle(t("orders:confirm-order"), "primary");
									setShowComponent(true);
								}}
							>
								{t("orders:place-order")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
	function setTitle(title, color) {
		var modalTitle = document.getElementById("modal-suggestion-title");
		var modalHeader = document.getElementById("modal-suggestion-header");
		var modalFooter = document.getElementById("modal-suggestion-footer");
		var modalButton = document.getElementById("modal-suggestion-footer-button");
		var modalButtonText = document.getElementById("modal-suggestion-footer-button-txt");
		modalTitle.innerHTML = title;
		modalHeader.className = `modal-header bg-${color}`;
		modalFooter.className = `modal-footer bg-${color}`;
		modalButton.className = `btn btn-${color}-light`;
		modalButtonText.className = `text-black`;
		modalButtonText.innerHTML = t("orders:go-to-cart");
	}
	function setSuggestion() {
		var
			suggModal = (
				<div
					className="modal fade"
					id="suggestionModal"
					aria-hidden="true"
					tabIndex="-1"
				>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header bg-primary-gradient" id="modal-suggestion-header">
								<h5 className="modal-title" id="modal-suggestion-title">{t("confirm-order")}</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={() => {
										setShowComponent(false);
										setOrderedProducts();
									}
									}
								></button>
							</div>
							<div className="modal-body">
								{showComponent && (
									<DynamicComponent
										properties={props}
									/>
								)}
							</div>
							<div className="modal-footer  bg-secondary-gradient" id="modal-suggestion-footer">
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
									id="modal-suggestion-footer-button"
									onClick={() => {
										window.location.href = `/${_cpLocal.companyId}/cart`;
									}}
								>
									<span id="modal-suggestion-footer-button-txt">{t("orders:confirm-order")}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);


		return suggModal;
	}

	function setQty(add, item) {
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			var qty = getQty(item);
			if (add) {
				qty++;
			} else {
				if (qty > 1) {
					qty--;
				}
			}
			el.innerHTML = qty;
			item.TotalQty = qty;
			setTotalPrice(item);
		}
	}
	function getQty(item) {
		var qty = 1;
		var el = document.getElementById("total-qty-" + item.BasketId);
		if (el != null) {
			qty = parseInt(el.innerHTML);
		}
		return qty;
	}
	function setTotalPrice(item) {
		// get element total-price
		var totalPrice = document.getElementById("total-price-" + item.BasketId);
		// set visible
		if (totalPrice != null) {
			var ttUnit = getTotalPrice(item);
			var qty = document.getElementById("total-qty-" + item.BasketId).innerHTML;
			totalPrice.innerHTML =
				(parseFloat(ttUnit) * parseInt(qty)).toFixed(2) +
				" " +
				_cpLocal.currency;
			var baskets = _basketModel.getCpyBasket(_cpLocal.companyId);
			// get basket with item.BasketId == BasketId
			var basket = baskets.products.find((x) => x.BasketId == item.BasketId);
			// set qty and price
			basket.TotalQty = qty;
			basket.TotalPrice = (parseFloat(ttUnit) * parseInt(qty)).toFixed(2);
			_basketModel.updateBaskets(baskets);
		}
		_basketModel.setBasketPriceAndQty({
			currency: _cpLocal.currency,
			cpid: _cpLocal.companyId
		});
	}
	function getTotalPrice(item) {
		var price = parseFloat(item.TotalUnitPrice);
		return price.toFixed(2);
	}
	function submitLogin() {
		_userModel.getSetUser(global.LOGGED_USER);
		// reload url
		window.location.reload();
	}
	function setLoginModal() {
		if (props.showLogin == false) return null;
		return (
			<div
				className="modal modal-xl fade"
				id="loginModal"
				aria-hidden="true"
				tabIndex="-1"
			>
				<div className="modal-dialog  modal-dialog-centered">
					<div className="modal-content ">
						<div className="modal-body w-100">
							<div className="m-2" id="step-content-0">
								<div className="accordion" id="accordion1">
									{setItem1()}
									{setItem2()}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-transparent w-100"
								data-bs-dismiss="modal"
							>
								{t("login:need-gest")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
	function setItem1() {
		return (
			<div className="accordion-item m-2">
				<h2 className="accordion-header">
					<button
						className="accordion-button bg-light"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapse1"
						aria-expanded="true"
						aria-controls="collapse1"
					>
						<span className="w-100 text-center">
							<div className="container-inline-centered">
								<h2 className="text-info">{t("login:already-registered")}</h2>
								<img
									src={require("../../../rits-package/img/logos/AO2-reduit.png")}
									alt="AO2"
									className="img-sm"
								/>
							</div>
						</span>
					</button>
				</h2>
				<div
					id="collapse1"
					className="accordion-collapse collapse show"
					data-bs-parent="#accordion1"
				>
					<LoginForm
						properties={{
							pageName: "login",
							trNamespace: "login",
							submitFunction: submitLogin
						}}
					></LoginForm>
				</div>
			</div>
		);
	}
	function setItem2() {
		if (props.showLogin == false) return null;
		return (
			<div className="accordion-item m-2">
				<h2 className="accordion-header">
					<button
						className="accordion-button bg-light"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapse2"
						aria-expanded="false"
						aria-controls="collapse2"
					>
						<span className="w-100 text-center">
							<div className="container-inline-centered">
								<h2 className="text-info">{t("login:need-register")}</h2>
								<img
									src={require("../../../rits-package/img/logos/AO2-reduit.png")}
									alt="AO2"
									className="img-sm"
								/>
							</div>
						</span>
					</button>
				</h2>
				<div
					id="collapse2"
					className="accordion-collapse collapse"
					data-bs-parent="#accordion1"
				>
					<CreateAccount
						properties={{
							pageName: "create-account",
							trNamespace: "login",
							submitFunction: submitLogin
						}}
					></CreateAccount>
				</div>
			</div>
		);
	}

	return loaded ? (
		<>
			<nav
				className="navbar navbar-expand-lg bg-primary-gradient txt-primary shadow fixed-top"
				key={componentId}
			>
				<div className="container-fluid container-inline-spaced">
					{start(true, false)}
					<div className="container-inline-spaced">
						<div className="d-lg-none">{setBasket()}</div>
						<button
							className="navbar-toggler m-1 mb-0 mt-0"
							type="button"
							aria-controls="navbarText"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={() => {
								// Get element by id navbarSupportedContent
								var el = document.getElementById("navbarText");
								// if el has class show remove
								if (el.classList.contains("show")) {
									el.classList.remove("show");
								} else {
									el.classList.add("show");
								}
							}}
						>
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<div className="collapse navbar-collapse p-2 " id="navbarText">
						{setMenuItems()}
					</div>
				</div>
				<div className="d-none d-lg-block">{setBasket()}</div>
			</nav>
			{setOffCanvas()}
			{setLoginModal()}
			{setSuggestion()}
		</>
	) : (
		<nav
			className="navbar navbar-expand-lg bg-primary-gradient txt-primary  shadow fixed-top"
			key={componentId}
		>
			<div className="container-fluid container-inline-spaced">
				{start(true, true)}
			</div>
		</nav>
	);
};

HeadMenu.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	addedClass: PropTypes.string,
	textClass: PropTypes.string,
	menuButtonClass: PropTypes.string,
	showOrders: PropTypes.bool,
	// bool with default value = true
	showMenu: PropTypes.bool,
	showLogin: PropTypes.bool
};

DynamicComponent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	addedClass: PropTypes.string,
	textClass: PropTypes.string,
	menuButtonClass: PropTypes.string,
	showOrders: PropTypes.bool,
	// bool with default value = true
	showMenu: PropTypes.bool,
	showLogin: PropTypes.bool
}

export default HeadMenu;
